import React, { useState, useEffect } from 'react';
import GenericTemplate from '../components/templates/GenericTemplate';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { db } from '../firebase';

// xross admin
import { getXrossUserList } from '../admin/firebase/user';

// interface
import { XROSS_USER_PROFILE, XROSS_FRIEND } from '../common/interface';

import {
  getFriendList,
  getFriendRequestList,
  getFriendPendingList,
} from '../common/firebase/friend';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    padding: '0px 8px',
  },
});

const UserList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [userName, setUserName] = useState('');
  const [xrossUserList, setXrossUserList] = useState<XROSS_USER_PROFILE[]>([]);
  const [nextList, setNextList] = useState<any>(new Date());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('@@@@@@@@@@ clear!!');
    setXrossUserList([]);
    setNextList(new Date());
    setUser();
  }, [userName]);

  const setUser = async () => {
    if (isLoading) return;
    setIsLoading(true);
    console.log('初回サーチ');
    const list = await getXrossUserList(100, null, userName, 'userName');
    setXrossUserList(list);
    if (list.length > 0) {
      // const latest = list[list.length - 1].createdAt;
      const latest = list[list.length - 1];
      if (latest !== undefined) setNextList(latest);
    }

    setIsLoading(false);
  };

  const handleNextPage = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const list = await getXrossUserList(100, nextList, userName, 'userName');
    setXrossUserList((prevList) => [...prevList, ...list]);
    if (list.length > 0) {
      // const latest = list[list.length - 1].createdAt;
      const latest = list[list.length - 1];
      if (latest !== undefined) setNextList(latest);
    }

    setIsLoading(false);
  };

  const [friendUser, setFriendUser] = useState<XROSS_FRIEND[]>([]);

  const friend = async (uid: string) => {
    // uid = "Fc3jSJLYXiVCVQdBGKwEuYpampe2";
    const users = await getFriendList(uid);
    setFriendUser(users);
    return users.length;
  };

  const handleClick = (id: string) => {
    console.log('push:' + id);
    // history.push("/UserDetailView/" + id);
    history.push('/UserDetail/' + id);
  };

  const toDateStrings = (seconds: number) => {
    const dateStr = String(new Date(seconds));
    // console.log('dateStr=', dateStr);
    return dateStr;
  };

  return (
    <GenericTemplate title="ユーザリスト">
      <input
        type="text"
        placeholder="ユーザ名"
        value={userName}
        onChange={(e) => {
          setUserName(e.target.value);
        }}
      />
      <button onClick={setUser}>検索</button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>uid</TableCell>
              <TableCell>xross ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Avatar</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Profile</TableCell>
              {/*   <TableCell>Home Location</TableCell> */}
              <TableCell>Home Location Name</TableCell>
              {/* <TableCell>規定の位置</TableCell> */}
              <TableCell>既定の位置</TableCell>
              <TableCell>既定の公開範囲</TableCell>
              <TableCell>作成日時</TableCell>
              <TableCell>更新日時</TableCell>
              <TableCell>友達</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {xrossUserList.map((xrossUser, idx) => (
              <TableRow
                key={idx}
                // key={xrossUser.id}
                hover
                onClick={(event) => handleClick(xrossUser.id)}
              >
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  <div>{xrossUser.id}</div>

                  <div>{xrossUser.email}</div>
                </TableCell>
                <TableCell>{xrossUser.xrossUserID}</TableCell>
                <TableCell>{xrossUser.xrossUserName}</TableCell>
                <TableCell>
                  <Avatar src={xrossUser.xrossAvatarURL} />
                </TableCell>
                <TableCell>{xrossUser.xrossUserStatus}</TableCell>
                <TableCell>{xrossUser.profile}</TableCell>
                {/* <TableCell>{xrossUser.homeGLocation}</TableCell> */}
                <TableCell>{xrossUser.homeGLocationLabel}</TableCell>
                <TableCell>
                  {xrossUser.isDefaultLocationAtHome
                    ? 'ホームを設定'
                    : 'GPS位置'}
                </TableCell>
                <TableCell>{xrossUser.defaultPostDisclosureRange}</TableCell>
                <TableCell>
                  {xrossUser.createdAt?.seconds
                    ? toDateStrings(xrossUser.createdAt?.seconds * 1000)
                    : 0}
                </TableCell>
                <TableCell>
                  {xrossUser.lastRefreshAt?.seconds
                    ? toDateStrings(xrossUser.lastRefreshAt?.seconds * 1000)
                    : 0}
                </TableCell>
                <TableCell>
                  <>
                    {() => {
                      friend(xrossUser.id);
                    }}
                  </>
                </TableCell>
                <TableCell>
                  <></>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={handleNextPage}
        disabled={isLoading}
      >
        次のLIST
      </Button>
    </GenericTemplate>
  );
};
export default UserList;
