import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import { XROSS_POST_EX, XROSS_COMMENT, XROSS_LIKED } from '$common/interface';

// コレクション名定義
import { CL_USER, CL_POST, SCL_LIKED, SCL_COMMENT } from '../../common/define';

// 投稿状態名定義
import { _STORED_, _TEMPORARILY_, _DELETED_ } from '../../common/define';
import { _PUBLIC_, _BETWEEN_FRIENDS_ } from '../../common/define';
import { _NORMAL_, _PR_ } from '../../common/define';
// import { renderSourcePropTypes } from 'react-native-render-html/lib/typescript/RenderHTMLSource';

/*
 * -----------------------------------------------------------------
 * 新規投稿
 * -----------------------------------------------------------------
 */
export const createPost = async (postDataSet: XROSS_POST_EX) => {
  console.log('createPost ', postDataSet);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  try {
    await db.collection(CL_POST).add({
      ...postDataSet,
      createdAt: timestamp,
      lastRefreshAt: timestamp,
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

/*
 * -----------------------------------------------------------------
 * 投稿を更新する
 * -----------------------------------------------------------------
 */
export const updatePost = async (postId: string, params: any) => {
  const updateParems = {
    ...params,
    lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  await db.collection(CL_POST).doc(postId).update(updateParems);
};

/*
 * -----------------------------------------------------------------
 * 投稿を更新する（下書きから投稿）
 * -----------------------------------------------------------------
 */
export const updateDraftToPost = async (postId: string, params: any) => {
  const updateParems = {
    ...params,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
  };
  await db.collection(CL_POST).doc(postId).update(updateParems);
};

export type Operator =
  | '<'
  | '<='
  | '=='
  | '>'
  | '>='
  | '!='
  | 'array-contains'
  | 'array-contains-any'
  | 'in'
  | 'not-in';

export interface PostSearchCondition {
  field: string;
  operator: Operator;
  value: string;
}

// 検索条件を付けて投稿を取得する
export const searchPost = async (
  filters?: PostSearchCondition[]
): Promise<XROSS_POST_EX[]> => {
  const postRef = db.collection(CL_POST);
  let query = postRef.limit(100);
  filters?.map((f) => (query = query.where(f.field, f.operator, f.value)));
  const ss = await query.orderBy('createdAt', 'desc').get();
  return ss.docs.map((doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX));
};

/*
 * -----------------------------------------------------------------
 * 投稿一覧を取得する
 * -----------------------------------------------------------------
 */
// export const getPostList = async (
//   limitNum: number,
//   lastData: any,
//   uid?: string,
//   xrossUserId?: string,
//   xrossUserName?: string
// ): Promise<XROSS_USER_PROFILE[]> => {
//   console.log('getXrossUSerList called :');
//   const docid = 'mMEfM383qDQpJgiy3eR1FWLne3q1';

//   // set query
//   let query = db.collection(CL_USER);
//   try {
//     // uid 指定の時
//     if (uid !== undefined && uid !== '') {
//       const ss = await query.doc(uid).get();
//       const user = ss.data() as XROSS_USER_PROFILE;
//       user.id = uid;
//       const userList = [user];
//       console.log('getXrossUSerList 結果 ', userList.length);
//       return userList;
//     } else {
//       // if (xrossUserId !== undefined && uid !== '') {
//       //   query = query.where('xrossUserId', '==', xrossUserId);
//       // }
//       // if (xrossUserName !== undefined && uid !== '') {
//       //   query = query.where('xrossUserName', '==', xrossUserName);
//       // }
//       const ss = await query
//         .orderBy('createdAt', 'desc')
//         .startAfter(lastData)
//         .limit(limitNum)
//         .get();

//       const userList = ss.docs.map(
//         (doc) => ({ ...doc.data(), id: doc.id } as XROSS_USER_PROFILE)
//       );

//       console.log('getXrossUSerList 結果 ', userList.length);
//       return userList;
//     }
//   } catch (e) {
//     console.log('getXrossUSerList error ', e);
//   }
//   return [];
// };

/*
 * -----------------------------------------------------------------
 * 投稿一覧を取得する
 * -----------------------------------------------------------------
 */
export const getPostList = async (
  limitNum: number,
  lastData: any,
  searchKey: string
): Promise<XROSS_POST_EX[]> => {
  console.log('getPostList limitNum=', limitNum, ' lastData=', lastData);
  try {
    // let query = db.collection(CL_POST).where('postStatus', '==', _STORED_);

    // if (searchKey !== '') {
    //   query = query.orderBy('uid');
    //   if (lastData?.uid) {
    //     query = query.startAfter(lastData.uid);
    //   } else {
    //     query = query.startAt(searchKey);
    //   }
    //   query = query.endAt(`${searchKey}\uf8ff`);
    // } else {
    //   // 検索条件なし
    //   console.log('検索条件なし ', lastData);
    //   if (lastData == null) {
    //     query = query.orderBy('createdAt', 'desc').startAfter(new Date());
    //   } else {
    //     console.log('lstData=', lastData);
    //     query = query
    //       .orderBy('createdAt', 'desc')
    //       .startAfter(lastData.createdAt);
    //   }
    // }

    let query = db.collection(CL_POST).where('postStatus', '==', _STORED_);

    if (searchKey !== '') {
      // 検索キーがある場合の処理
      query = query.where('uid', '==', searchKey).orderBy('createdAt', 'desc');
      if (lastData?.createdAt) {
        query = query.startAfter(lastData.createdAt);
      } else {
        query = query.startAt(new Date());
      }
    } else {
      // 検索条件なし
      console.log('検索条件なし ', lastData);
      query = query.orderBy('createdAt', 'desc');
      if (lastData == null) {
        query = query.startAfter(new Date());
      } else {
        console.log('lastData=', lastData);
        query = query.startAfter(lastData.createdAt);
      }
    }

    query = query.limit(limitNum);
    const ss = await query.get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('++++++++++ getPostList 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPostList error ', e);
  }
  return [];
};

export const getPostListNext = async (
  uid: string,
  lastData: any
): Promise<XROSS_POST_EX[]> => {
  //console.log('getPostList ', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', 'in', [_PUBLIC_, _BETWEEN_FRIENDS_])
      .where('category', '==', _NORMAL_)
      .where('deliveryUIDList', 'array-contains', uid)
      .orderBy('createdAt', 'desc')
      .startAfter(lastData)
      .limit(10)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('++++++++++ getPostListNext 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPostListNext error ', e);
  }
  return [];
};

/*
 * -----------------------------------------------------------------
 * filter を指定して投稿一覧を取得する
 * -----------------------------------------------------------------
 */
export const getPostListByFilter = async (
  limitNum: number,
  lastData: any,
  filter?: Record<string, any> // 複数の検索条件を持つオブジェクトを追加
): Promise<XROSS_POST_EX[]> => {
  console.log(
    'getPostListByFilter filter=',
    filter,
    'limitNum=',
    limitNum,
    ' lastData=',
    lastData
  );
  try {
    // let query = db.collection(CL_POST).where('postStatus', '==', _STORED_);
    let query = db
      .collection(CL_POST)
      .where('postStatus', 'in', [
        _STORED_,
        'BAN_STORED_',
        'UNS_STORED_',
        'UNSBAN_STORED_',
      ]);

    // searchCriteria オブジェクトに基づいて検索条件を動的に追加
    for (const key in filter) {
      if (filter.hasOwnProperty(key)) {
        query = query.where(key, '==', filter[key]);
      }
    }

    const ss = await query
      .orderBy('createdAt', 'desc')
      .startAfter(lastData)
      .limit(limitNum)
      .get();

    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );

    console.log('++++++++++ getPostListByFilter 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPostListByFilter error ', e);
  }
  return [];
};

export const getPostListRefresh = async (
  uid: string,
  latestData: any
): Promise<XROSS_POST_EX[]> => {
  //console.log('getPostList ', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', 'in', [_PUBLIC_, _BETWEEN_FRIENDS_])
      .where('category', '==', _NORMAL_)
      .where('deliveryUIDList', 'array-contains', uid)
      .orderBy('createdAt', 'desc')
      .endBefore(latestData)
      .limit(100)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('++++++++++ getPostListRefresh 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPostListRefresh error ', e);
  }
  return [];
};

// 更新された投稿のみを取得する
export const getPostListChangeUserInfo = async (
  uid: string
  // lastData: any
): Promise<XROSS_POST_EX[]> => {
  //console.log('getPostList ', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', 'in', [_PUBLIC_, _BETWEEN_FRIENDS_])
      .where('category', '==', _NORMAL_)
      .where('deliveryUIDList', 'array-contains', uid)
      .orderBy('userInfoUpdateAt', 'desc')
      // .startAfter(lastData)
      .limit(500)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('++++++++++ getPostListChangeUserInfo 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPostListChangeUserInfo error ', e);
  }
  return [];
};

/*
 * -----------------------------------------------------------------
 * 一般投稿を取得する（ハッシュタグで絞込み）
 * -----------------------------------------------------------------
 */
export const getPublicPostList = async (
  hashtagList: string[]
): Promise<XROSS_POST_EX[]> => {
  try {
    const ss = await db
      .collection(CL_POST)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', 'in', [_PUBLIC_])
      .where('category', '==', _NORMAL_)
      .where('hashtag', 'array-contains', hashtagList)
      .orderBy('createdAt', 'desc')
      .limit(50)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('++++++++++ getPublicPostList 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('---------- getPublicPostList error ', e);
  }
  return [];
};

/*
 * -----------------------------------------------------------------
 * 広告投稿を取得する
 * -----------------------------------------------------------------
 */
export const getPrPostList = async (uid: string): Promise<XROSS_POST_EX[]> => {
  console.log('getPrPostList ', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('postStatus', '==', _STORED_)
      .where('category', '==', _PR_)
      .orderBy('createdAt', 'desc')
      .limit(50)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('getPrPostList 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('getPostList error ', e);
  }
  return [];
};

//
// TODO:firebaseのインデックスを設定して並び順を指定する
// TODO:limitを指定してページング処理を追加する
/*
 * -----------------------------------------------------------------
 * 自分の投稿を取得する
 * -----------------------------------------------------------------
 */
export const searchStorerdPost = async (
  uid: string
): Promise<XROSS_POST_EX[]> => {
  console.log('searchStorerdPost called :', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('uid', '==', uid)
      .where('postStatus', '==', _STORED_)
      .orderBy('createdAt', 'desc')
      //.limit(10)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('searchStorerdPost 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('searchStorerdPost error ', e);
  }
  return [];
};

// TODO:firebaseのインデックスを設定して並び順を指定する
// TODO:limitを指定してページング処理を追加する
/*
 * -----------------------------------------------------------------
 * 友達の投稿を取得する
 * -----------------------------------------------------------------
 */
export const searchFriendStorerdPost = async (
  uid: string
): Promise<XROSS_POST_EX[]> => {
  console.log('searchFriendStorerdPost called :', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('uid', '==', uid)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', 'in', [_PUBLIC_, _BETWEEN_FRIENDS_])
      .orderBy('createdAt', 'desc')
      //.limit(10)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('searchFriendStorerdPost 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('searchFriendStorerdPost error ', e);
  }
  return [];
};

// TODO:firebaseのインデックスを設定して並び順を指定する
// TODO:limitを指定してページング処理を追加する
/*
 * -----------------------------------------------------------------
 * 他人の投稿を取得する
 * -----------------------------------------------------------------
 */
export const searchOtheUsersStorerdPost = async (
  uid: string
): Promise<XROSS_POST_EX[]> => {
  console.log('searchOtheUsersStorerdPost called :', uid);
  try {
    const ss = await db
      .collection(CL_POST)
      .where('uid', '==', uid)
      .where('postStatus', '==', _STORED_)
      .where('disclosureRange', '==', _PUBLIC_)
      .orderBy('createdAt', 'desc')
      //.limit(10)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('searchOtheUsersStorerdPost 結果 ', postList.length);
    return postList;
  } catch (e) {
    console.log('searchOtheUsersStorerdPost error ', e);
  }
  return [];
};

// TODO:firebaseのインデックスを設定して並び順を指定する
// TODO:limitを指定してページング処理を追加する
/*
 * -----------------------------------------------------------------
 * 自分の下書き投稿を取得する
 * -----------------------------------------------------------------
 */
export const searchDraftPost = async (
  uid: string
): Promise<XROSS_POST_EX[]> => {
  const ss = await db
    .collection(CL_POST)
    .where('uid', '==', uid)
    .where('postStatus', '==', _TEMPORARILY_)
    .orderBy('createdAt', 'desc')
    .limit(10)
    .get();
  const postList = ss.docs.map(
    (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
  );
  console.log('searchDraftPost 結果 ', postList.length);
  return postList;
};

// TODO:firebaseのインデックスを設定して並び順を指定する
// TODO:limitを指定してページング処理を追加する
/*
 * -----------------------------------------------------------------
 * 指定したユーザがタグ付けされている投稿を取得する
 * -----------------------------------------------------------------
 */
export const searchUserTaggedPost = async (
  uid: string
): Promise<XROSS_POST_EX[]> => {
  console.log('searchUserTaggedPost>> called uid = ', uid);
  try {
    const ss = await db
      .collection(CL_POST)

      .where('postStatus', '==', _STORED_)
      .where('friendtag', 'array-contains', uid)
      .orderBy('createdAt', 'desc')
      .limit(10)
      .get();
    const postList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    console.log('searchUserTaggedPost 結果 ', postList.length);
    return postList;
  } catch (err) {
    console.log('searchUserTaggedPost>> ', err);
    return [];
  }
};

/*
 * -----------------------------------------------------------------
 * postId から、返信コメントリスト を取得する
 * -----------------------------------------------------------------
 */
export const getCommentList = async (postId: string) => {
  console.log('getCommentList>> called postId = ', postId);

  try {
    const snapshot = await db
      .collection(CL_POST)
      .doc(postId)
      .collection(SCL_COMMENT)
      .orderBy('createdAt', 'desc')
      .get();
    const commentList = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_COMMENT)
    );
    // console.log('返信コメント = ', commentList);
    return commentList;
  } catch (err) {
    console.log('getCommentList>> ', err);
    return [];
  }
};

/*
 * -----------------------------------------------------------------
 * postId から、いいねしたユーザリスト を取得する
 * -----------------------------------------------------------------
 */
export const getLikedUserList = async (postId: string) => {
  console.log('getLikedUserList>> called ' + postId);
  try {
    const snapshot = await db
      .collection(CL_POST)
      .doc(postId)
      .collection(SCL_LIKED)
      .get();
    const likedUserList = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_LIKED)
    );
    //console.log('いいねの数 = ', snapshot.size);
    //console.log('いいねしたユーザ = ', likedUserList);
    return likedUserList;
  } catch (err) {
    console.log('getLikedUserList>> ', err);
    return [];
  }
};

/*
 * -----------------------------------------------------------------
 * uid から、いいねした投稿リスト を取得する
 * -----------------------------------------------------------------
 */
export const getLikedPostList = async (uid: string) => {
  console.log('getLikedPostList>> called ' + uid);
  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_LIKED)
      .orderBy('createdAt', 'desc')
      .get();
    const likedPostList = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_LIKED)
    );
    //console.log('いいねの数 = ', snapshot.size);
    //console.log('いいねした投稿 = ', likedPostList);
    return likedPostList;
  } catch (err) {
    console.log('getLikedPostList>> ', err);
    return [];
  }
};

/*
 * -----------------------------------------------------------------
 * 投稿数を取得する
 * -----------------------------------------------------------------
 */
export const getXrossPostNum = async () => {
  console.log('getXrossPostNum>> called');

  try {
    const snapshot = await db.collection('posts').get();
    console.log(snapshot.size);
    console.log(snapshot.empty);
    console.log('post num = ', snapshot.size);
    return snapshot.size;
  } catch (err) {
    console.log('getXrossPostNum>> ', err);
    return 0;
  }
};

/*
 * -----------------------------------------------------------------
 * 投稿内容を取得する
 * -----------------------------------------------------------------
 */
export const getPostDetail = async (postId: string) => {
  console.log('getPostDetail>> called');
  try {
    const snapshot = await db.collection(CL_POST).doc(postId).get();
    const postDoc = snapshot.data() as XROSS_POST_EX;
    postDoc.id = postId;
    return postDoc;
  } catch (err) {
    console.log('getPostDetail>> ', err);
    return null;
  }
};

/*
 * -----------------------------------------------------------------
 * 投稿内容を取得する
 * -----------------------------------------------------------------
 */
export const getPostDetailByList = async (postIdlist: string[]) => {
  console.log('getPostDetailByList>> called');

  // 配列を10個づつに分割
  const MAX_NUM = 10;
  const arryNum = Math.ceil(postIdlist.length / MAX_NUM);
  let retPP: XROSS_POST_EX[] = [];
  // 配列に10分割した配列を格納する
  // [ [],[],[],..... ]
  const list = new Array(arryNum)
    .fill(arryNum)
    .map((_, i) => postIdlist.slice(i * MAX_NUM, (i + 1) * MAX_NUM));

  try {
    for (let i = 0; i < list.length; i++) {
      const pp = await getPostDetailByList10(list[i]);
      retPP = retPP.concat(pp);
    }
    console.log('========== retPP:', retPP.length);

    // オリジナルのリストに並び替え
    const postDetailArry: XROSS_POST_EX[] = [];
    for (let i = 0; i < postIdlist.length; i++) {
      const passValue: XROSS_POST_EX | undefined = retPP.find(function (
        element
      ) {
        return element.id === postIdlist[i];
      });

      if (passValue !== undefined) {
        postDetailArry.push(passValue);
      }
    }
    return postDetailArry;
  } catch (err) {
    console.log('getPostDetailByList err ', err);
    return [];
  }
};

const getPostDetailByList10 = async (postId: string[]) => {
  console.log('getPostDetailByList10>> called');
  try {
    const snapshot = await db
      .collection(CL_POST)
      .where(firebase.firestore.FieldPath.documentId(), 'in', postId)
      .get();

    const postDocList: XROSS_POST_EX[] = snapshot.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_POST_EX)
    );
    return postDocList;
  } catch (err) {
    console.log('getPostDetailByList10>> ', err);
    return [];
  }
};

/*
 * -----------------------------------------------------------------
 * 「いいね」する
 * -----------------------------------------------------------------
 */
export const onLikedPost = async (uid: string, postId: string) => {
  console.log('onLikedPost>> called uid = ' + uid, ' postId = ', postId);

  await db.collection(CL_POST).doc(postId).collection(SCL_LIKED).doc(uid).set({
    uid: uid,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });

  await db.collection(CL_USER).doc(uid).collection(SCL_LIKED).doc(postId).set({
    postId: postId,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

/*
 * -----------------------------------------------------------------
 * 「いいね」を外す
 * -----------------------------------------------------------------
 */
export const offLikedPost = async (uid: string, postId: string) => {
  console.log('offLikedPost>> called uid = ' + uid, ' postId = ', postId);
  const userRef = db
    .collection(CL_USER)
    .doc(uid)
    .collection(SCL_LIKED)
    .doc(postId);

  await userRef
    .delete()
    .then(() => {
      console.log('Document successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing document: ', error);
    });

  const postRef = db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_LIKED)
    .doc(uid);

  await postRef
    .delete()
    .then(() => {
      console.log('Document successfully deleted!');
    })
    .catch((error) => {
      console.error('Error removing document: ', error);
    });
};

/*
 * -----------------------------------------------------------------
 * 投稿に対してコメントする
 * -----------------------------------------------------------------
 */
export const putComment = async (
  uid: string,
  postId: string,
  comment: string,
  userName: string,
  avatarUrl: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db.collection(CL_POST).doc(postId).collection(SCL_COMMENT).add({
    uid: uid,
    comment: comment,
    userName,
    avatarUrl,
    status: _STORED_,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

/*
 * -----------------------------------------------------------------
 * コメントを修正する
 * -----------------------------------------------------------------
 */
export const updateComment = async (
  uid: string,
  postId: string,
  commentId: string,
  comment: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_COMMENT)
    .doc(commentId)
    .set({
      uid: uid,
      comment: comment,
      status: _STORED_,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

/*
 * -----------------------------------------------------------------
 * コメントを削除する
 * -----------------------------------------------------------------
 */
export const deleteComment = async (
  uid: string,
  postId: string,
  commentId: string
) => {
  console.log('putComment>> called uid = ' + uid, ' postId = ', postId);

  await db
    .collection(CL_POST)
    .doc(postId)
    .collection(SCL_COMMENT)
    .doc(commentId)
    .set({
      status: _DELETED_,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};
