import React, { useEffect, useState } from 'react';
import GenericTemplate from '../components/templates/GenericTemplate';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Modal from '@material-ui/core/Modal'; // kamome
import IconButton from '@material-ui/core/IconButton';
import styles from '../components/templates/Template.module.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { Avatar } from '@material-ui/core';

//import { GetXrossServiceStatus } from "../common/GetXrossServiceStatus2";
// xross common function
import { getInquiryListByFilter } from '../common/firebase/inquiry';

// xross interface
import { XROSS_INQUIRY } from '../common/interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    modal: {
      outline: 'none',
      position: 'absolute',
      with: 400,
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10),
    },
  })
);

enum CONTACT_CATEGORY {
  '希望・要望',
  'ユーザーに関する報告',
  '投稿コンテンツに関する報告',
  'その他お問い合わせ',
}

const Inquiry: React.FC = () => {
  const classes = useStyles();
  const [inquiry, setInquiry] = useState<XROSS_INQUIRY[]>([]);
  const [nextList, setNextList] = useState<any>(new Date());

  const [searchUID, setSearchUID] = useState(''); // UIDを入力するためのステート

  const filter = {
    uid: searchUID,
  };

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const list: XROSS_INQUIRY[] = await getInquiryListByFilter(
      1000,
      nextList,
      filter
    );

    // console.log('list=', list);
    // for (let i = 0; i < list.length; i++) {
    //   console.log(i, +' createdAt=', list[i].createdAt);
    // }

    setInquiry([...inquiry, ...list]);

    if (list.length > 0) {
      const latest = list[list.length - 1].createdAt;
      setNextList(latest);
    }
  };

  const history = useHistory();
  const handleClick = (id: string) => {
    console.log('PostList ->' + id);
    // history.push('/PostDetail/' + id);
  };

  return (
    <GenericTemplate title="問い合わせ管理">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>日時</TableCell>
              <TableCell>xrossユーザ名</TableCell>
              <TableCell>email</TableCell>
              <TableCell>投稿時の名前</TableCell>
              <TableCell>カテゴリ</TableCell>
              <TableCell>タイトル</TableCell>
              <TableCell>詳細</TableCell>
            </TableRow>
          </TableHead>
          <>
            <TableBody>
              {inquiry.map((inquiry, idx) => (
                <TableRow
                  key={inquiry.id}
                  hover
                  // onClick={(event) => handleClick(inquiry.uid)}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>
                    {inquiry.createdAt?.toDate().toLocaleString()}
                  </TableCell>

                  <TableCell>
                    <div>
                      <Avatar src={inquiry.xrossAvatarURL} />
                      {inquiry.xrossUserName}
                    </div>
                    <div>{inquiry.uid}</div>
                  </TableCell>

                  <TableCell>{inquiry.email}</TableCell>
                  <TableCell>{inquiry.name}</TableCell>
                  <TableCell>{CONTACT_CATEGORY[inquiry.category]}</TableCell>
                  <TableCell>{inquiry.title}</TableCell>
                  <TableCell>{inquiry.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      </TableContainer>
      <Button variant="contained" color="primary" onClick={getFirebaseItems}>
        次のLIST
      </Button>
    </GenericTemplate>
  );
};
export default Inquiry;
