import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';

import firebase from 'firebase/app';

import { db, storage, timestamp } from '../firebase';

import { Avatar, Button, IconButton } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import { makeStyles } from '@material-ui/core/styles';
import MessageIcon from '@material-ui/icons/Message';
import SendIcon from '@material-ui/icons/Send';

import GenericTemplate from '../components/templates/GenericTemplate';
import styles from './Styles.module.css';

// xross interfaces
import { XROSS_POST_EX, CONTENTS_ATTRIBUTE } from '../common/interface';
import { ContactSupportOutlined } from '@material-ui/icons';

// lib
import { textAnalyzer } from '../common/lib/textAnalyzer';

// firebase lib
import { createPost } from '../common/firebase/post';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// xross.club 運営管理画面
// 新規投稿

const CreatePost: React.FC = () => {
  const classes = useStyles();
  const [posts, setPosts] = useState<XROSS_POST_EX[]>([]);

  // 投稿ボタン有効
  const [buttonEnable, setButtonEnable] = useState(false);

  // redux でログインユーザの管理を行う
  const user = useSelector(selectUser);
  const avatar = user.photoUrl;

  const [tweetImage, setTweetImage] = useState<File | null>(null);
  const [tweetMsg, setTweetMsg] = useState('');

  useEffect(() => {
    if (tweetImage && tweetMsg) setButtonEnable(true);
  }, [tweetImage, tweetMsg]);

  /*
   * -----------------------------------------------------------------
   * 画像ピックアップ
   * -----------------------------------------------------------------
   */
  const onChangeImageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      console.log('+++++ 画像ピックアップ ', e.target.files![0]);

      setTweetImage(e.target.files![0]);
      e.target.value = '';
    }
  };
  /*
   * -----------------------------------------------------------------
   * 投稿データの作成
   * -----------------------------------------------------------------
   */
  // const [newPostDate, setNewPostData] = useState<XROSS_POST_EX>();
  // // 動画のダウンロードURLを含む属性
  // const [contentsAttributeList, setContentsAttributeList] = useState<
  //   CONTENTS_ATTRIBUTE[]
  // >([]);
  // // カバー画像リスト
  // const [coverContents, setCoverContents] = useState<string[]>([]);

  const createNewPostData = (
    uid: string,
    userName: string,
    avatarUrl: string,
    comment: string,
    friendArray: string[],
    imageUrl: string
  ) => {
    const gLocation = {
      latitude: 0,
      longitude: 0,
    };

    const region = {
      latitude: 0,
      longitude: 0,
    };
    const locationLabel = 'テストロケーション';

    const coverContents: string[] = [imageUrl];
    const contentsAttributeList: CONTENTS_ATTRIBUTE[] = [
      {
        coverContents: imageUrl,
        mediaType: 'image',
        mediaUrl: imageUrl,
      },
    ];

    // dummy friend tag
    const dummyFriendTag = [];
    for (let i = 0; i < 100; i++) {
      dummyFriendTag.push('test' + i);
    }

    return {
      id: '',
      uid: uid,
      userName: userName,
      avatarUrl: avatarUrl,
      text: comment,
      hashtag: textAnalyzer(comment).tags || [],
      friendtag: [],
      contents: coverContents,
      contentsAttributeList: contentsAttributeList,
      gLocation: gLocation,
      displayGLocation: region, // 表示用位置
      displayGLocationLabel: locationLabel || '', // 表示用ラベル
      disclosureRange: '_BETWEEN_FRIENDS_', // 公開版範囲
      postStatus: '_STORED_',
      deliveryUIDList: friendArray,
      category: '_NORMAL_',
      // createdAt: timestamp,
    };
  };

  /*
   * -----------------------------------------------------------------
   * 投稿
   * -----------------------------------------------------------------
   */
  const sendTweet = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // ブラウザがリフレッシュすることを抑制する
    // 画像有り無しで処理を分岐する
    console.log('+++++ tweetImage ----- ', tweetImage);

    if (tweetImage) {
      // fileName はユニークである必要があるためランダムに生成する
      // const S =
      //   'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      // // ランダムな16文字を生成する
      // const N = 16;
      // const randomChar = Array.from(crypto.getRandomValues(new Uint32Array(N)))
      //   .map((n) => S[n % S.length])
      //   .join('');
      // const fileName = randomChar + '_' + tweetImage.name;

      console.log('+++++ tweetImage +++++ ', tweetImage);

      const unique = Math.random().toString(36).slice(-8);
      const uid = user.uid;
      const name = tweetImage.name;

      // const path = `contents/${uid}/${unique}_${name}`;

      const path = `contents/TEST/${uid}/${unique}_${name}`;

      const uploadTweetImg = storage.ref(path).put(tweetImage);

      console.log('+++++ uploadTweetImg path ', path);

      // storage に対して何らかの変化があった時に後処理を行う関数
      uploadTweetImg.on(
        firebase.storage.TaskEvent.STATE_CHANGED,

        // 投稿の進捗状態（Progress）
        () => {}, // 今回は何もしない
        // エラー時
        (err) => {
          alert(err.message);
        },
        async () => {
          // 正常終了した場合
          // アップロードした画像のURLを取得する
          await storage
            .ref(path)
            // .child(name)
            .getDownloadURL()
            .then(async (url) => {
              console.log('===== posted!!');
              // 画像アップロードが成功したので投稿する

              const userName = '';
              const comment = tweetMsg;
              const friendArray: string[] = [uid];
              const ps: XROSS_POST_EX = createNewPostData(
                uid,
                userName,
                avatar,
                comment,
                friendArray,
                url
              );
              await createPost(ps);

              // await db.collection('posts').add({
              //   avatar: user.photoUrl,
              //   image: url,
              //   text: tweetMsg,
              //   timestamp,
              //   username: user.displayName,
              //   uid: user.uid, // 投稿者のUIDも入れるべき！
              // });
            });
        }
      );
      // } else {
      //   //   // コレクション名（posts）にオブジェクトを追加する
      //   //   db.collection('posts').add({
      //   //     avatar: user.photoUrl,
      //   //     image: '',
      //   //     text: tweetMsg,
      //   //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      //   //     username: user.displayName,
      //   //     uid: user.uid, // 投稿者のUIDも入れるべき！
      //   //   });
    }
    // 投稿が完了するとTweetメッセージと画像を初期化する
    setTweetImage(null);
    setTweetMsg('');
  };
  return (
    <GenericTemplate title="新規投稿">
      <>
        <form onSubmit={sendTweet}>
          <div className={styles.tweet_form}>
            <Avatar className={styles.tweet_avatar} src={avatar} />

            <textarea
              className={styles.postInputText}
              placeholder="投稿コメント"
              autoFocus
              value={tweetMsg}
              onChange={(e) => setTweetMsg(e.target.value)}
            />

            <div>
              <IconButton>
                <label>
                  <AddAPhotoIcon
                    className={
                      tweetImage
                        ? styles.tweet_addIconLoaded
                        : styles.tweet_addIcon
                    }
                  />
                  <input
                    className={styles.tweet_hiddenIcon}
                    type="file"
                    onChange={onChangeImageHandler}
                  />
                </label>
              </IconButton>
            </div>
          </div>

          <Button
            type="submit"
            disabled={!buttonEnable}
            className={
              buttonEnable ? styles.tweet_sendBtn : styles.tweet_sendDisableBtn
            }
          >
            投稿する
          </Button>
        </form>
        <hr></hr>
        <div>＜投稿内容＞</div>
        <div>イメージファイル名:{tweetImage?.name}</div>
        {/* <div>
          <img src={tweetImage?.name} />
        </div> */}
        <div>コメント:{tweetMsg}</div>
        <hr></hr>
      </>
    </GenericTemplate>
  );
};
export default CreatePost;
