import { db } from '../../firebase';

// xross interface
import { XROSS_SERVICE_STATE } from '../interface';
// コレクション名定義
import { CL_STATUS } from '../define';

//
// サービスステータス を取得する
//
export const getXrossServiceStatus = async () => {
  console.log('getXrossServiceStatus>> called');
  const docid = '0';
  try {
    const snapshot = await db.collection(CL_STATUS).doc(docid).get();
    const status = snapshot.data() as XROSS_SERVICE_STATE;
    status.id = docid;
    console.log('status = ', status);
    return status;
  } catch (err) {
    console.log('getXrossServiceStatus>> ', err);
    return null;
  }
};

//
// メンテナンスモードにする
//
export const setOutOfService = async (isOutOfService: boolean) => {
  console.log('setOutOfService ', isOutOfService);
  const docid = '0';
  try {
    await db.collection(CL_STATUS).doc(docid).update({
      isOutOfService,
    });
  } catch (err) {
    console.log('setOutOfService>> ', err);
    return null;
  }
};
