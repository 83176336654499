/*
 * -----------------------------------------------------------------
 *
 * システムテスト用のデータを作成する
 *
 * -----------------------------------------------------------------
 */

import React, { useState, useEffect } from 'react';

import GenericTemplate from '../components/templates/GenericTemplate';
import { Button, Grid, Divider } from '@material-ui/core';

//interface
import { XROSS_SERVICE_STATE } from '../common/interface';

import {
  XROSS_MESSAGE,
  XROSS_GROUP_TALK_ROOM,
  XROSS_TALK_ROOM,
  XROSS_TALK,
  XROSS_TALK_HISTORY,
} from '../common/interface';

import { XROSS_CLIP, XROSS_CLIP_FOLDER } from '../common/interface';

// admin xross utils
import {
  deleteAllDcocuments,
  makeOnLikedPost,
  makeOffLikedPost,
} from '../adminUtil/adminXrossUtil';

import { onLikedPost, offLikedPost } from '../common/firebase/post';
import { XROSS_INFORMATION, XROSS_INFO_READ } from '../common/interface';

// システムテスト用データ登録
import { makeTestUserData } from '../adminUtil/SystemTestDataFactory/makeUserData';
import { makeTestPostData } from '../adminUtil/SystemTestDataFactory/makePostData';
import { replyComment1000 } from '../adminUtil/TestDataStore/STReplyComment';
import { folderName100 } from '../adminUtil/TestDataStore/STFolderName';
import { user10000 as userDataSet } from '../adminUtil/TestDataStore//STUserDataSet';

// xross common function
import {
  requestFriendTo,
  requestFriendToByUserInfo,
  rejectFriendRequest,
  cancelFriendRequest,
  applyFriendRequest,
  brokeFriendTo,
} from '../common/firebase/friend';

import { getXrossPostNum, putComment } from '../common/firebase/post';
import { getXrossServiceStatus } from '../common/firebase/status';
import { getXrossUserNum } from '../common/firebase/user';
import { createInformation } from '../common/firebase/info';

// message
import {
  setMessageRoom,
  setMessageRoomName,
  getMessageHistory,
  getMessageRoomList,
  getMessageRoomInfo,
  getMessageList,
  setMessage,
  deleteMessage,
  editMessage,
} from '../common/firebase/message';

// collection
import {
  addClip,
  removeClip,
  makeClipFolder,
  removeClipFolder,
  addClipToFolder,
  removeClipFromFrolder,
  getClipList,
  getClipFolderList,
  getClipFolderInfo,
  hasCollection,
} from '../common/firebase/collection';

// stamp
import { getMyStampInfo, getBuiltinStampInfo } from '../common/firebase/stamp';

// xross web page styles
import { xrossUseStyles } from '../components/pageStyles';
import { TextField } from '@mui/material';

const EntrySystemTestData: React.FC = () => {
  console.log('SystemSetup');
  //const classes = useStyles();
  const classes = xrossUseStyles();

  const [xrossServiceState, setXrossServiceState] =
    useState<XROSS_SERVICE_STATE>({
      id: '0', // ドキュメントID
      isOutOfService: false,
      isNewUserUnavailable: false,
      isNewPostUnavailable: false,
      // createdAt: null,
      // lastRefreshAt: null,
    });

  const [userNum, setUserNum] = useState<number>(0);
  const [postNum, setPostNum] = useState<number>(0);
  const [registPostNum, setRegistPostNum] = useState<number>(0);
  const [postText, setPostText] = useState<string>('');

  const [friendTo, setFriendTo] = useState<string>('');
  const [friendFrom, setFriendFrom] = useState<string>('');

  const [myUid, setMyUid] = useState<string>('');
  const [target, setTarget] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  //const [xrossMessage, setXrossMessage] = useState<XROSS_TALK>('');

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const status = await getXrossServiceStatus();
    if (status) {
      setXrossServiceState(status);
    }
    const userNum = await getXrossUserNum();
    if (userNum) {
      setUserNum(userNum);
    }
    const postNum = await getXrossPostNum();
    if (postNum) {
      setPostNum(postNum);
    }
  };

  /*
   * -----------------------------------------------------------------
   * ユーザデータの登録（１０,０００件）
   * -----------------------------------------------------------------
   */
  const onMakeTestUserData = (pref: string) => {
    console.log('user data entry-----');
    makeTestUserData(pref);
  };

  /*
   * -----------------------------------------------------------------
   * 投稿データの登録（１０,０００件）
   * -----------------------------------------------------------------
   */
  const [postUserUid, setPostUserUid] = useState('');
  const [deliveryUid, setDeliveryUid] = useState('');
  const onMakeTestPostData = () => {
    console.log('POST data entry-----');
    if (postUserUid === '') {
      console.log('uid is empty!');
      return;
    }

    makeTestPostData(postUserUid, deliveryUid, registPostNum, postText);
  };

  /*
   * -----------------------------------------------------------------
   * メッセージの登録（１,０００件）
   * -----------------------------------------------------------------
   */
  const [messageToUid, setMessageToUid] = useState('');
  const [messageFromUid, setMessageFromUid] = useState('');
  const [roomId, setRoomId] = useState('');

  useEffect(() => {
    if (messageToUid !== '' && messageFromUid !== '') {
      console.log('set room id+++++');
      setRoomId('ROOM_ID-' + messageToUid + '_' + messageFromUid);
    }
  }, [messageToUid, messageFromUid]);

  const onMakeTestMessageData = () => {
    console.log('10000 message data entry-----');

    if (messageToUid === '' || messageFromUid === '' || roomId === '') {
      console.log('uid or roomId is empty!');
      return;
    }
    console.log('message entry!');

    // testMessage1000();
    testMessage1000rev();
  };

  const testMessage1000 = async () => {
    const MESSAGE_NUM = 1000;
    const avatar1 =
      'https://i0.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_woman.png?w=600&ssl=1';
    const avatar2 =
      'https://i1.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_man.png?w=600&ssl=1';

    console.log('message entry! start ');

    for (let i = 0; i < MESSAGE_NUM; i++) {
      console.log('message entry!  ', i);
      const message = i + ' こんにちは！';
      const xrossMessage: XROSS_TALK = {
        text: message,
        image: [],
        stamp: [],
        fromUid: messageToUid,
        sendUserName: '送る人',
        sendAvatar: avatar1,
        recvUserName: '受け取る人',
        recvAvatar: avatar2,
        createdAt: new Date(),
        lastRefreshAt: new Date(),
        deleteFlag: false,
      };

      await setMessage(messageToUid, roomId, xrossMessage);
    }
  };

  const testMessage1000rev = async () => {
    const MESSAGE_NUM = 1000;
    const avatar2 =
      'https://i0.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_woman.png?w=600&ssl=1';
    const avatar1 =
      'https://i1.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_man.png?w=600&ssl=1';

    console.log('message entry! start ');

    for (let i = 0; i < MESSAGE_NUM; i++) {
      console.log('message entry!  ', i);
      const message = i + ' こんにちは！';
      const xrossMessage: XROSS_TALK = {
        text: message,
        image: [],
        stamp: [],
        fromUid: messageFromUid,
        sendUserName: '送る人',
        sendAvatar: avatar1,
        recvUserName: '受け取る人',
        recvAvatar: avatar2,
        createdAt: new Date(),
        lastRefreshAt: new Date(),
        deleteFlag: false,
      };

      await setMessage(messageFromUid, roomId, xrossMessage);
    }
  };

  /*
   * -----------------------------------------------------------------
   * メッセージの登録（１,０００文字）
   * -----------------------------------------------------------------
   */
  const onMakeTestMessageData1000 = () => {
    console.log('10000 文字 message data entry-----');

    testMessage1000moji();
  };
  const testMessage1000moji = async () => {
    const MESSAGE_NUM = 1;
    const message = replyComment1000;
    const avatar1 =
      'https://i0.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_woman.png?w=600&ssl=1';
    const avatar2 =
      'https://i1.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2018/12/th_snow_board_man.png?w=600&ssl=1';

    console.log('message entry! start ');

    for (let i = 0; i < MESSAGE_NUM; i++) {
      console.log('message entry!  ', i);

      const xrossMessage: XROSS_TALK = {
        text: message,
        image: [],
        stamp: [],
        fromUid: messageFromUid,
        sendUserName: '送る人',
        sendAvatar: avatar1,
        recvUserName: '受け取る人',
        recvAvatar: avatar2,
        createdAt: new Date(),
        lastRefreshAt: new Date(),
        deleteFlag: false,
      };

      await setMessage(messageFromUid, roomId, xrossMessage);
    }
  };

  /*
   * -----------------------------------------------------------------
   * フォルダ作成（１００個）
   * -----------------------------------------------------------------
   */
  const [makeFolderUid, setMakeFolderUid] = useState('');
  const FOLDER_NUM = 100;
  const onMakeTestFolder = async () => {
    console.log('make folder-----', makeFolderUid);
    if (makeFolderUid === '') {
      console.log('uid is empty!');
      return;
    }
    //  フォルダを作成する
    for (let i = 0; i < FOLDER_NUM; i++) {
      console.log('folderName ', i, ' ', folderName100[i]);
      await makeClipFolder(makeFolderUid, i + folderName100[i]);
    }
  };

  /*
   * -----------------------------------------------------------------
   * フォルダに投稿を100個格納する
   * -----------------------------------------------------------------
   */
  const [folderId, setFolderId] = useState('');
  const CLIP_NUM = 100;
  const onMakeTestClipToFolder = async () => {
    console.log('into clip-----', makeFolderUid);
    if (makeFolderUid === '' || folderId === '') {
      console.log('uid or　folderId is empty!');
      return;
    }
    //  フォルダに投稿を格納する
    for (let i = 0; i < CLIP_NUM + 1; i++) {
      console.log('clip postId ', i);
      const postId = 'kamome' + String(i);
      await addClip(makeFolderUid, postId);
      await addClipToFolder(makeFolderUid, postId, folderId);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 友だち申請（承認待ち）
   * -----------------------------------------------------------------
   */
  const [targetFriendRequestUid, setTargetFriendRequestUid] = useState('');

  const onMakeTestFriendrequest = async (num: number) => {
    console.log('Friend 申請-----', targetFriendRequestUid);
    if (targetFriendRequestUid === '') {
      console.log('uid is empty!');
      return;
    }
    //  友だち申請する
    let start = 0;
    let end = 0;

    switch (num) {
      case 100:
        start = 1;
        end = 101;
        break;
      case 300:
        start = 101;
        end = 301;
        break;
      case 500:
        start = 301;
        end = 501;
        break;
      case 1000:
        start = 501;
        end = 1001;
        break;
      case 3000:
        start = 1001;
        end = 3001;
        break;
      case 4999:
        start = 3001;
        end = 5001;
        break;
    }
    console.log(
      'requestFriend from ',
      friendFrom,
      ' start:',
      start,
      ' end:',
      end
    );
    for (let i = start; i < end; i++) {
      const friendFrom = 'test' + i;
      console.log('friendFrom ', friendFrom);

      // const fromUser = {
      //   id: friendFrom,
      //   xrossUserName: userDataSet[i][2],
      //   xrossUserID: 'test' + i,
      //   xrossAvatarURL: userDataSet[i][3],

      //   xrossUserStatus: '',
      //   profile: '',

      //   homeGLocationLabel: '',
      //   isDefaultLocationAtHome: true,
      // };

      // const toUser = {
      //   id: targetFriendRequestUid,
      //   xrossUserName: userDataSet[i][2],
      //   xrossUserID: 'test' + i,
      //   xrossAvatarURL: userDataSet[i][3],

      //   xrossUserStatus: '',
      //   profile: '',

      //   homeGLocationLabel: '',
      //   isDefaultLocationAtHome: true,
      // };
      // await requestFriendToByUserInfo(fromUser, toUser);

      await requestFriendTo(friendFrom, targetFriendRequestUid);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 友だち申請を許可する
   * -----------------------------------------------------------------
   */
  const [targetFriendUid, setTargetFriendUid] = useState('');
  const onMakeTestFriend = async (num: number) => {
    console.log('Friend 申請許可-----', makeFolderUid);
    if (targetFriendUid === '') {
      console.log('uid is empty!');
      return;
    }
    //  友だち申請を許可する
    let start = 0;
    let end = 0;

    switch (num) {
      case 100:
        start = 1;
        end = 101;
        break;
      case 300:
        start = 101;
        end = 301;
        break;
      case 500:
        start = 301;
        end = 501;
        break;
      case 1000:
        start = 501;
        end = 1001;
        break;
      case 3000:
        start = 1001;
        end = 3001;
        break;
      case 4999:
        start = 3001;
        end = 5000;
        break;
    }
    console.log(
      'requestFriend from ',
      friendFrom,
      ' start:',
      start,
      ' end:',
      end
    );
    for (let i = start; i < end; i++) {
      const friendFrom = 'test' + i;
      console.log('friendFrom ', friendFrom);

      await applyFriendRequest(targetFriendUid, friendFrom);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 自分から友だち申請する　１０,０００件
   * -----------------------------------------------------------------
   */
  const [fromFriendRequestUid, setFromFriendRequestUid] = useState('');

  const onMakeTestFriendrequestTo = async (num: number) => {
    console.log('Friend 申請-----', targetFriendRequestUid);
    if (fromFriendRequestUid === '') {
      console.log('uid is empty!');
      return;
    }
    //  友だち申請する
    let start = 0;
    let end = 0;

    switch (num) {
      case 100:
        start = 1;
        end = 101;
        break;
      case 300:
        start = 101;
        end = 301;
        break;
      case 500:
        start = 301;
        end = 501;
        break;
      case 1000:
        start = 501;
        end = 1001;
        break;
      case 3000:
        start = 1001;
        end = 3001;
        break;
      case 5000:
        start = 3001;
        end = 5001;
        break;
    }
    console.log(
      'requestFriend from ',
      friendFrom,
      ' start:',
      start,
      ' end:',
      end
    );
    for (let i = start; i < end; i++) {
      const friendTo = 'user' + i;
      console.log('friendTo ', friendTo);
      // await requestFriendTo(friendTo, fromFriendRequestUid);
      await requestFriendTo(fromFriendRequestUid, friendTo);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 投稿に対していいね！する
   * -----------------------------------------------------------------
   */
  const [targetPostId, setTargetPostId] = useState('');
  const [likedNum, setLikedNum] = useState('');

  const onMakeTestLiked = async () => {
    console.log('いいね-----', targetPostId);
    if (targetPostId === '') {
      console.log('post id is empty!');
      return;
    }
    //   投稿に対していいね！する
    let start: number = 1;
    let end: number = Number(likedNum);
    for (let i = start; i < end + 1; i++) {
      const likedUid = 'test' + i;
      console.log('target post id = ', targetPostId, ' liked uid ', likedUid);

      await onLikedPost(likedUid, targetPostId);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 投稿に対していいね！する
   * -----------------------------------------------------------------
   */
  const [commentPostId, setCommentPostId] = useState('');
  const [commentNum, setCommentNum] = useState('');
  const text = '返信コメントです';
  const onMakeComment = async () => {
    console.log('返信コメント', commentPostId);
    if (commentPostId === '') {
      console.log('commentPostId is empty!');
      return;
    }
    //   投稿に対して返信コメントする
    let start: number = 1;
    let end: number = Number(commentNum);
    for (let i = start; i < end + 1; i++) {
      const commentUid = 'test' + i;
      console.log(
        'target post id = ',
        commentPostId,
        ' liked uid ',
        commentUid
      );

      await onComment(commentUid, commentPostId, '(' + i + ')' + text);
    }
  };

  /*
   * -----------------------------------------------------------------
   * お知らせを投稿する
   * -----------------------------------------------------------------
   */

  const [infoNum, setInfoNum] = useState('');
  const [infoPrefix, setInfoPrefix] = useState('');

  const infoTitle = '運営からのお知らせです';
  const infoText = '運営からのお知らせ本文です。';

  const onMakeInfo = async () => {
    if (infoNum === '') {
      console.log('infoNum is empty!');
      return;
    }
    // お知らせを投稿する
    let start: number = 1;
    let end: number = Number(infoNum);

    for (let i = start; i < end + 1; i++) {
      const newInfo: XROSS_INFORMATION = {
        id: '', //  ドキュメントID
        createdAt: null,
        lastRefreshAt: null,
        category: '_INFO_',
        title: infoPrefix + ' ' + infoTitle + '-' + i,
        text: infoText + i,
        status: '_STORED_', // 投稿状態
        messageTo: [],
      };

      console.log(infoPrefix + ' ' + infoTitle + '-' + i);
      await createInformation(newInfo);
    }
  };

  /*
   * -----------------------------------------------------------------
   * bookmark する
   * -----------------------------------------------------------------
   */

  const [doBookmarkUID, setDoBookmarkUID] = useState('');
  const [bookmarkNum, setBookmarkNum] = useState('');

  const onMakeBookmark = async () => {
    if (doBookmarkUID === '' || bookmarkNum === '') {
      console.log('doBookmarkUID or bookmarkNum is empty!');
      return;
    }

    let start: number = 1;
    let end: number = Number(bookmarkNum);

    for (let i = start; i < end + 1; i++) {
      const postId = 'kamome' + i;
      console.log('bookmarkする ', doBookmarkUID, ' -> ', postId);
      await addClip(doBookmarkUID, postId);
    }
  };

  /*
   * -----------------------------------------------------------------
   * 投稿にいいね！する
   * -----------------------------------------------------------------
   */

  const [doLikeUID, setDoLikeUID] = useState('');
  const [likeNum, setLikeNum] = useState('');

  const onMakeLike = async () => {
    if (doLikeUID === '' || likeNum === '') {
      console.log('doLikeUID or likeNum is empty!');
      return;
    }

    let start: number = 10000;
    let end: number = Number(likeNum);

    for (let i = start; i < end + start; i++) {
      // const postId = 'kamome' + i;
      const postId = 'maruko' + i;
      console.log('いいねする ', doLikeUID, ' -> ', postId);
      await onLikedPost(doLikeUID, postId);
    }
  };
  //
  //
  //

  //
  //
  //
  //
  //
  //
  //
  /*
   * -----------------------------------------------------------------
   * 返信コメント
   * -----------------------------------------------------------------
   */
  const onComment = async (uid: string, postId: string, comment: string) => {
    await putComment(uid, postId, comment);
  };

  /*
   * -----------------------------------------------------------------
   * firebase collection 削除
   * -----------------------------------------------------------------
   */

  const deleteUsers = () => {
    deleteAllDcocuments('xrossUser');
  };

  const deletePosts = () => {
    deleteAllDcocuments('posts');
  };

  const onLiked = async () => {
    const postId = '3YQF1QNeElAOFRjN0ES9';
    await makeOnLikedPost(postId);
  };

  const offLiked = async () => {
    const postId = '3YQF1QNeElAOFRjN0ES9';
    await makeOffLikedPost(postId);
  };

  const requestFriend = async () => {
    await requestFriendTo(friendFrom, friendTo);
  };

  const rejectFriend = async () => {
    await rejectFriendRequest(myUid, target);
  };
  const cancelFriend = async () => {
    await cancelFriendRequest(friendFrom, friendTo);
  };

  const applyFriend = async () => {
    await applyFriendRequest(myUid, target);
  };

  const brokeFriend = async () => {
    await brokeFriendTo(myUid, target);
  };

  //
  //
  // message
  //
  //
  const testSetMessageGroup = async () => {
    await setMessageRoom(myUid, target);
  };

  const testSetMessageRoomName = async () => {
    await setMessageRoomName(myUid, id, name);
  };

  const testSetMessage = async () => {
    const xrossMessage: XROSS_TALK = {
      text: name,
      image: [],
      stamp: [],
      fromUid: '',
      sendUserName: '',
      sendAvatar: '',
      recvUserName: '',
      recvAvatar: '',
      createdAt: new Date(),
      lastRefreshAt: new Date(),
      deleteFlag: false,
    };

    await setMessage(myUid, id, xrossMessage);
  };

  const testGetMessageHistory = async () => {
    const ret = await getMessageHistory(myUid);
    console.log('getMessageHistory ==========');
    console.log('getMessageHistory uid =', myUid);
    console.log('getMessageHistory ret =', ret);
  };

  const testGetMessageRoomList = async () => {
    const ret = await getMessageRoomList(myUid);
    console.log('getMessageRoomList ==========');
    console.log('getMessageRoomList uid =', myUid);
    console.log('getMessageRoomList ret =', ret);
  };

  const testGetMessageRoomInfo = async () => {
    const ret = await getMessageRoomInfo(myUid, id);
    console.log('getMessageRoomInfo ==========');
    console.log('getMessageRoomInfo uid =', myUid, ' id = ', id);
    console.log('getMessageRoomInfo ret =', ret);
  };

  const testGetMessageList = async () => {
    const ret = await getMessageList(myUid, id);
    console.log('getMessageList ==========');
    console.log('getMessageList uid =', myUid, ' id = ', id);
    console.log('getMessageList ret =', ret);
  };

  //
  //
  // collection
  //
  //
  const testAddClip = async () => {
    await addClip(myUid, id);
    console.log('addClip ==========');
    console.log('addClip uid =', myUid, ' postId = ', id);
  };

  const testRemoveClip = async () => {
    await removeClip(myUid, id);
    console.log('removeClip ==========');
    console.log('removeClip uid =', myUid, ' postId = ', id);
  };

  const testMakeClipFolder = async () => {
    await makeClipFolder(myUid, name);
    console.log('makeClipFolder ==========');
    console.log('makeClipFolder uid =', myUid, ' フォルダ名 = ', name);
  };

  const testRemoveClipFolder = async () => {
    await removeClipFolder(myUid, id);
    console.log('removeClipFolder ==========');
    console.log('removeClipFolder uid =', myUid, ' フォルダID = ', id);
  };

  const testAddClipToFolder = async () => {
    await addClipToFolder(myUid, name, id);
    console.log('addClipToFolder ==========');
    console.log(
      'addClipToFolder uid =',
      myUid,
      'ref= ',
      name,
      ' フォルダID = ',
      id
    );
  };

  const testRemoveClipFromFrolder = async () => {
    await removeClipFromFrolder(myUid, name, id);
    console.log('addClipToFolder ==========');
    console.log(
      'addClipToFolder uid =',
      myUid,
      'ref= ',
      name,
      ' フォルダID = ',
      id
    );
  };

  const testGetClipList = async () => {
    const List = await getClipList(myUid);
    console.log('getClipList ==========');
    console.log('getClipList uid =', myUid);
    console.log('getClipList List =', List);
  };

  const testGetClipFolderList = async () => {
    const List = await getClipFolderList(myUid);
    console.log('getClipFolderList ==========');
    console.log('getClipFolderList uid =', myUid);
    console.log('getClipFolderList List =', List);
  };

  const testGetClipFolderInfo = async () => {
    const info = await getClipFolderInfo(myUid, id);
    console.log('getClipFolderInfo ==========');
    console.log('getClipFolderInfo uid =', myUid, ' フォルダID =', id);
    console.log('getClipFolderInfo info =', info);
  };

  const testHasCollection = async () => {
    const info = await hasCollection(myUid, id);
    console.log('hasCollection ==========');
    console.log('hasCollection uid =', myUid, ' postId =', id);
    console.log('hasCollection 投稿内容 =', info);
  };

  const testGetMyStampInfo = async () => {
    const info = await getMyStampInfo(myUid);
    console.log('getMyStampInfo ==========');
    console.log('getMyStampInfo uid =', myUid);
    console.log('getMyStampInfo スタンプ情報 =', info);
  };

  const testGetBuiltinStampInfo = async () => {
    const info = await getBuiltinStampInfo();
    console.log('getBuiltinStampInfo ==========');
    console.log('getBuiltinStampInfo uid =', myUid);
    console.log('getBuiltinStampInfo スタンプ情報 =', info);
  };

  return (
    <GenericTemplate title="xross.club システムテスト用データ投入">
      <h3>ユーザ作成</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestUserData('test')}
          >
            testxxxx ユーザを10,000件作成する
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestUserData('user')}
          >
            userxxxx ユーザを10,000件作成する
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="primary" onClick={deleteUsers}>
            全ユーザを削除する
          </Button>
        </Grid>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>投稿データの投入</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="postUserUid"
            type="text"
            label="postUserUid"
            autoComplete="postUserUid"
            value={postUserUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostUserUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="deliveryUid"
            type="text"
            label="deliveryUid"
            autoComplete="deliveryUid"
            value={deliveryUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDeliveryUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="postText"
            type="text"
            label="投稿テキスト"
            autoComplete="postText"
            value={postText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPostText(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="registPostNum"
            type="text"
            label="投稿数"
            autoComplete="registPostNum"
            value={registPostNum}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRegistPostNum(Number(e.target.value));
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={onMakeTestPostData}
          >
            投稿を作成する
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="primary" onClick={deletePosts}>
            全投稿データを削除する
          </Button>
        </Grid>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>メッセージの返信作成（1000件）</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="messageToUid"
            type="text"
            label="messageToUid"
            autoComplete="messageToUid"
            value={messageToUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMessageToUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="messageFromUid"
            type="text"
            label="messageFromUid"
            autoComplete="messageFromUid"
            value={messageFromUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMessageFromUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="roomId"
            type="text"
            label="roomId"
            autoComplete="roomId"
            value={roomId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setRoomId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={onMakeTestMessageData}
          >
            メッセージを1,000件作成する
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={onMakeTestMessageData1000}
          >
            1,000文字のメッセージを作成する
          </Button>
        </Grid>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>フォルダ作成（100件）</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="makeFolderUid"
            type="text"
            label="makeFolderUid"
            autoComplete="makeFolderUid"
            value={makeFolderUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMakeFolderUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={onMakeTestFolder}
          >
            フォルダを100件作成する
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="makeFolderUid"
            type="text"
            label="makeFolderUid"
            autoComplete="makeFolderUid"
            value={makeFolderUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMakeFolderUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="folderId"
            type="text"
            label="folderId"
            autoComplete="folderId"
            value={folderId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFolderId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={onMakeTestClipToFolder}
          >
            フォルダをに投稿100件を格納する
          </Button>
        </Grid>
      </Grid>

      <h3></h3>
      <Divider />

      <h3>友達申請（承認待ち）（4999件）</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="targetFriendRequestUid"
            type="text"
            label="targetFriendRequestUid"
            autoComplete="targetFriendRequestUid"
            value={targetFriendRequestUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTargetFriendRequestUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(100)}
          >
            友だち申請（ID 1〜100）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(300)}
          >
            友だち申請（ID 101〜300）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(500)}
          >
            友だち申請（ID 301〜500）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(1000)}
          >
            友だち申請（ID 501〜1000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(3000)}
          >
            友だち申請（ID 1001〜3000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequest(4999)}
          >
            友だち申請（ID 3001〜5000）
          </Button>
        </Grid>
      </Grid>

      <h3></h3>
      <Divider />

      <h3>友達申請／承認（4,999件）</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="targetFriendUid"
            type="text"
            label="targetFriendUid"
            autoComplete="targetFriendUid"
            value={targetFriendUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTargetFriendUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(100)}
          >
            友だち許可（ID 1〜100）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(300)}
          >
            友だち許可（ID 101〜300）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(500)}
          >
            友だち許可（ID 301〜500）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(1000)}
          >
            友だち許可（ID 501〜1,000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(3000)}
          >
            友だち許可（ID 1,001〜3,000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriend(4999)}
          >
            友だち許可（ID 3,001〜4,999）
          </Button>
        </Grid>
      </Grid>

      <h3></h3>
      <Divider />

      <h3>自分から友達申請する（5,000件）</h3>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="fromFriendRequestUid"
            type="text"
            label="fromFriendRequestUid"
            autoComplete="fromFriendRequestUid"
            value={fromFriendRequestUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFromFriendRequestUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(100)}
          >
            友だち申請する（ID user1〜100）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(300)}
          >
            友だち申請する（ID user101〜300）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(500)}
          >
            友だち申請する（ID user301〜500）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(1000)}
          >
            友だち申請する（ID user501〜1,000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(3000)}
          >
            友だち申請する（ID user1,001〜3,000）
          </Button>
          <h3></h3>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => onMakeTestFriendrequestTo(5000)}
          >
            友だち申請する（ID user3,001〜5,000）
          </Button>
        </Grid>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>投稿に対するいいね！（50000件）</h3>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="targetPostId"
          type="text"
          label="targetPostId"
          autoComplete="targetPostId"
          value={targetPostId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetPostId(e.target.value);
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="likedNum"
          type="text"
          label="いいねするUid(test1 - testxxxx)"
          autoComplete="likedNum"
          value={likedNum}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLikedNum(e.target.value);
          }}
        />

        <Button variant="contained" color="secondary" onClick={onMakeTestLiked}>
          投稿に対していいね！する
        </Button>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>返信コメント</h3>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="commentPostId"
          type="text"
          label="commentPostId"
          autoComplete="commentPostId"
          value={commentPostId}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCommentPostId(e.target.value);
          }}
        />

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="commentNum"
          type="text"
          label="返信コメントするUid(test1 - testxxxx)"
          autoComplete="commentNum"
          value={commentNum}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setCommentNum(e.target.value);
          }}
        />

        <Button variant="contained" color="secondary" onClick={onMakeComment}>
          返信コメント！する
        </Button>
      </Grid>
      <h3></h3>
      <Divider />

      <h3>お知らせ投稿</h3>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="infoPrefix"
          type="text"
          label="infoPrefix"
          autoComplete="infoPrefix"
          value={infoPrefix}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInfoPrefix(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="infoNum"
          type="text"
          label="投稿するお知らせの数"
          autoComplete="infoNum"
          value={infoNum}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setInfoNum(e.target.value);
          }}
        />

        <Button variant="contained" color="secondary" onClick={onMakeInfo}>
          お知らせを投稿する
        </Button>
      </Grid>

      <h3></h3>
      <Divider />

      <h3>いいね！追加</h3>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="doLikeUID"
          type="text"
          label="doLikeUID"
          autoComplete="doLikeUID"
          value={doLikeUID}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDoLikeUID(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="likeNum"
          type="text"
          label="いいねする投稿ID(kamemo1 - xxxx)"
          autoComplete="likeNum"
          value={likeNum}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLikeNum(e.target.value);
          }}
        />

        <Button variant="contained" color="secondary" onClick={onMakeLike}>
          投稿にいいねする
        </Button>
      </Grid>

      <h3></h3>
      <Divider />

      <h3>bookmarkする</h3>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="doBookmarkUID"
          type="text"
          label="doBookmarkUID"
          autoComplete="doBookmarkUID"
          value={doBookmarkUID}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDoBookmarkUID(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="bookmarkNum"
          type="text"
          label="Bookmarkする投稿ID(kamemo1 - xxxx)"
          autoComplete="bookmarkNum"
          value={bookmarkNum}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setBookmarkNum(e.target.value);
          }}
        />

        <Button variant="contained" color="secondary" onClick={onMakeBookmark}>
          Bookmarkする
        </Button>
      </Grid>

      <h3></h3>
      <Divider />

      <p />
    </GenericTemplate>
  );
};
export default EntrySystemTestData;
