import { db } from '../../firebase';
import firebase from 'firebase/app';
// xross interface
import { XROSS_USER_PROFILE, XROSS_PUBLIC_USER_INFO } from '$common/interface';
// コレクション名定義
import { CL_USER } from '../../common/define';
// ユーザ状態
import { _ACTIVE_ } from '../../common/define';
// 投稿の公開範囲
import { _BETWEEN_FRIENDS_ } from '../../common/define';

export type Operator =
  | '<'
  | '<='
  | '=='
  | '>'
  | '>='
  | '!='
  | 'array-contains'
  | 'array-contains-any'
  | 'in'
  | 'not-in';

export interface SearchCondition {
  field: string;
  operator: Operator;
  value: string;
}

// 検索条件を付けて投稿を取得する
// export const searchPost = async (
//   filters?: SearchCondition[]
// ): Promise<XROSS_USER_PROFILE[]> => {
//   const postRef = db.collection(CL_USER);
//   let query = postRef.limit(100);
//   filters?.map((f) => (query = query.where(f.field, f.operator, f.value)));
//   const ss = await query.orderBy('createdAt', 'desc').get();

//   return ss.docs.map(
//     (doc) => ({ ...doc.data(), id: doc.id } as XROSS_USER_PROFILE)
//   );
// };

export const search = async (filters?: SearchCondition[]) => {
  const postRef = db.collection(CL_USER);
  let query = postRef.limit(100);
  filters?.map((f) => (query = query.where(f.field, f.operator, f.value)));
  console.log('==========');
  console.log('query=', query);
};

/*
 * -----------------------------------------------------------------
 * ユーザ一覧を取得する
 * -----------------------------------------------------------------
 */
export const getXrossUSerList = async (
  limitNum: number,
  lastData: any,
  uid?: string,
  // xrossUserId?: string,
  xrossUserName?: string
): Promise<XROSS_USER_PROFILE[]> => {
  console.log('getXrossUSerList called :');
  // const docid = 'mMEfM383qDQpJgiy3eR1FWLne3q1';

  // set query
  let query = db.collection(CL_USER);
  try {
    // uid 指定の時
    if (uid !== undefined && uid !== '') {
      const ss = await query.doc(uid).get();
      const user = ss.data() as XROSS_USER_PROFILE;
      user.id = uid;
      const userList = [user];
      console.log('getXrossUSerList 結果 ', userList.length);
      return userList;
    } else {
      // if (xrossUserId !== undefined && uid !== '') {
      //   query = query.where('xrossUserId', '==', xrossUserId);
      // }
      // if (xrossUserName !== undefined && uid !== '') {
      //   query = query.where('xrossUserName', '==', xrossUserName);
      // }

      console.log('==========');
      console.log('========== getXrossUSerList limitNum ', limitNum);
      console.log('========== getXrossUSerList lastData ', lastData);
      console.log('========== getXrossUSerList xrossUserName ', xrossUserName);

      const ss = await query
        .orderBy('createdAt', 'desc')

        .startAfter(lastData)
        .limit(limitNum)
        .get();

      const userList = ss.docs.map(
        (doc) => ({ ...doc.data(), id: doc.id } as XROSS_USER_PROFILE)
      );

      console.log('getXrossUSerList 結果 ', userList.length);
      return userList;
    }
  } catch (e) {
    console.log('getXrossUSerList error ', e);
  }
  return [];
};

/*
 * -----------------------------------------------------------------
 * xross ユーザネーム を指定して前方一致検索ユーザ一覧を取得する
 * -----------------------------------------------------------------
 */
export const getXrossUserList = async (
  limitNum: number,
  lastData: any,
  searchKey: string,
  searchType: 'userName' | 'userId'
): Promise<XROSS_USER_PROFILE[]> => {
  console.log('getXrossUserList called:', searchKey, searchType);
  try {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      db.collection(CL_USER);
    // .where('xrossUserStatus', '==', '_ACTIVE_');

    if (searchKey !== '') {
      if (searchType === 'userName') {
        query = query.orderBy('xrossUserName');
        if (lastData?.xrossUserName) {
          query = query.startAfter(lastData.xrossUserName);
        } else {
          query = query.startAt(searchKey);
        }
        query = query.endAt(`${searchKey}\uf8ff`);
      } else if (searchType === 'userId') {
        query = query.orderBy('xrossUserId');
        if (lastData?.xrossUserId) {
          query = query.startAfter(lastData.xrossUserId);
        } else {
          query = query.startAt(searchKey);
        }
        query = query.endAt(`${searchKey}\uf8ff`);
      }
    } else {
      // 検索条件なし
      console.log('検索条件なし ', lastData);
      if (lastData == null) {
        query = query.orderBy('createdAt', 'desc').startAfter(new Date());
      } else {
        query = query
          .orderBy('createdAt', 'desc')
          .startAfter(lastData.createdAt);
      }
    }

    query = query.limit(limitNum);

    const ss = await query.get();
    const userList = ss.docs.map(
      (doc) =>
        ({
          ...doc.data(),
          id: doc.id,
        } as XROSS_USER_PROFILE)
    );

    console.log('getXrossUserList 結果', userList.length);
    return userList;
  } catch (e) {
    console.log('getXrossUserList error', e);
  }

  return [];
};

export const getXrossUSerListByUserName = async (
  limitNum: number,
  lastData: any,
  xrossUserName: string
): Promise<XROSS_USER_PROFILE[]> => {
  console.log('getXrossUSerListByUserName called :');
  // let query = db.collection(CL_USER);

  try {
    // let query = db.collection(CL_USER).where('xrossUserStatus', '==', _ACTIVE_);

    // const ss = await query
    //   .orderBy('createdAt', 'desc')

    //   .where('xrossUserName', '>=', xrossUserName)
    //   .where('xrossUserName', '<', xrossUserName + '\uf8ff')

    //   // .startAfter(lastData)
    //   // .startAt(xrossUserName) // 前方一致の開始点
    //   // .endAt(xrossUserName + '\uf8ff') // 前方一致の終了点
    //   .limit(limitNum)
    // .get();

    const ss = await db
      .collection(CL_USER)
      .where('xrossUserStatus', '==', '_ACTIVE_')
      .orderBy('xrossUserName')
      .startAt(xrossUserName)
      .endAt(xrossUserName + '\uf8ff')
      .limit(limitNum)
      .get();

    const userList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_USER_PROFILE)
    );

    console.log('getXrossUSerListByUserName 結果 ', userList.length);
    return userList;
  } catch (e) {
    console.log('getXrossUSerListByUserName error ', e);
  }
  return [];
};

// xross.club utils
// setXrossUSerProfile
// user を指定して、firebase の基本ユーザプロフィールを
// xrossUserProfile にセットする
export const setXrossUSerProfile = async (user: firebase.User) => {
  if (user == undefined) {
    console.log('>>xrossUserProfiile undefined!!');
    return false;
  }
  console.log('>>xrossUserProfiile uid=' + user.uid);

  // xrossUserProfile にuid 情報があるか検査する
  const docRef = db.collection(CL_USER).doc(user.uid);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  docRef.get().then((doc) => {
    if (!doc.exists) {
      // uid なし
      // xrossUserProfile 初期化
      console.log('uidなし uid=', user.uid);
      try {
        docRef.set({
          xrossUserID: '',
          xrossUserName: '',
          xrossAvatarURL: user.photoURL ? user.photoURL : '',
          xrossUserStatus: '_ACTIVE_',
          profile: '',
          homeGLocation: null,
          homeGLocationLabel: '',
          isDefaultLocationAtHome: false,
          defaultPostDisclosureRange: '_BETWEEN_FRIENDS_',
          createdAt: timestamp,
          lastRefreshAt: timestamp,
        });
      } catch (e) {
        console.log('setXrossUSerProfile error ', e);
      }
      return true; // 正常
    } else {
      // uid あり
      console.log('uidあり');
      return true; // 正常
    }
  });
};

//
// firebase の uid から、xrossUserProfile を取得する
//
export const getXrossUserProfile = async (uid: string) => {
  console.log('getXrossUserProfile>> called ', uid);
  const docid = uid;
  try {
    const snapshot = await db.collection(CL_USER).doc(docid).get();
    const user = { ...snapshot.data(), id: docid } as XROSS_USER_PROFILE;
    // uid は、ドキュメントIDとして使用しているため、メンバーにセットする
    // console.log('user = ', user);
    return user;
  } catch (err) {
    console.log('getXrossUserProfile>> ', err);
    return null;
  }
};

//
// firebase の uid から、公開ユーザ情報を取得する
//
export const getPublicUserInfo = async (uid: string) => {
  console.log('getPublicUserInfo>> called');
  const docid = uid;
  try {
    const snapshot = await db.collection(CL_USER).doc(docid).get();
    const user = snapshot.data() as XROSS_PUBLIC_USER_INFO;
    user.id = docid; // uid は、ドキュメントIDとして使用しているため、メンバーにセットする
    if (user.xrossUserStatus !== _ACTIVE_) {
      return null;
    } else {
      return user;
    }
  } catch (err) {
    console.log('getPublicUserInfo>> ', err);
    return null;
  }
};

//
// ユーザ情報を更新する
//
export const updateUser = async (uid: string, params: any) => {
  const docRef = db.collection(CL_USER).doc(uid);
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  try {
    if (!(await docRef.get()).exists) {
      docRef.set({
        xrossUserID: '',
        xrossUserName: '',
        xrossAvatarURL: '',
        xrossUserStatus: '_ACTIVE_',
        profile: '',
        homeGLocation: null,
        homeGLocationLabel: '',
        isDefaultLocationAtHome: false,
        defaultPostDisclosureRange: '_BETWEEN_FRIENDS_',
        createdAt: timestamp,
        lastRefreshAt: timestamp,
      });
    }
  } catch (e) {
    console.log('updateUser setup e=', e);
  }

  try {
    const updateParems = {
      ...params,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await db.collection(CL_USER).doc(uid).update(updateParems);
  } catch (e) {
    console.log('updateUser e=', e);
  }
};

//
// プッシュトークンを更新する
//
export const updatePushToken = async (uid: string, pushToken: string) => {
  await db
    .collection(CL_USER)
    .doc(uid)
    .update({
      pushToken: firebase.firestore.FieldValue.arrayUnion(pushToken),
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
};

//
// プッシュトークンを削除する
//
export const removePushToken = async (uid: string, pushToken: string) => {
  await db
    .collection(CL_USER)
    .doc(uid)
    .update({
      pushToken: firebase.firestore.FieldValue.arrayRemove(pushToken),
    });
};

//
// firebase の XrossUSerProfile を強制的に全更新する
//
export const updateXrossUserForce = async (
  xrossUserProfile: XROSS_USER_PROFILE
) => {
  try {
    const userRef = db.collection(CL_USER).doc(xrossUserProfile.id);
    console.log('updateXrossUserForce>> called uid = ', xrossUserProfile.id);

    await userRef.update({
      xrossUserID: xrossUserProfile.xrossUserID,
      xrossUserName: xrossUserProfile.xrossUserName,
      xrossAvatarURL: xrossUserProfile.xrossAvatarURL,
      xrossUserStatus: xrossUserProfile.xrossUserStatus,
      profile: xrossUserProfile.profile,
      homeGLocation: xrossUserProfile.homeGLocation,
      homeGLocationLabel: xrossUserProfile.homeGLocationLabel,
      isDefaultLocationAtHome: xrossUserProfile.isDefaultLocationAtHome,
      defaultPostDisclosureRange: xrossUserProfile.defaultPostDisclosureRange,
      lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

//
//ユーザ登録数を取得する
//
export const getXrossUserNum = async () => {
  console.log('getXrossUserNum>> called');

  try {
    const snapshot = await db.collection('xrossUser').get();
    console.log(snapshot.size);
    console.log(snapshot.empty);
    console.log('user num = ', snapshot.size);
    return snapshot.size;
  } catch (err) {
    console.log('getXrossUserNum>> ', err);
    return 0;
  }
};
