import React, { useState, useEffect } from 'react';
//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

// View
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

// xross interfaces
import { XROSS_POST, XROSS_LIKED } from '../common/interface';
// xross common function
import { getLikedUserList } from '../common/firebase/post';
// view compornent
import ListView from './ListView';

interface Props {
  postDataSet: XROSS_POST | undefined;
}

const PostLikedView: React.FC<Props> = ({ postDataSet }) => {
  const postId = postDataSet?.id;
  console.log('postId=' + postId);

  const [likedUser, setLikedUser] = useState<XROSS_LIKED[]>([]);

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    if (postId) {
      const users = await getLikedUserList(postId);
      setLikedUser(users);
    }
  };

  return (
    <>
      <h3>いいねしたユーザ（{likedUser.length}件）</h3>
      <div>postId={postId}</div>
      <TableContainer component={Paper}>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Avater</TableCell>
            <TableCell>userID</TableCell>
            {/* <TableCell>postID</TableCell> */}
            <TableCell>text</TableCell>
          </TableRow>
        </TableHead>
      </TableContainer>
      <div>
        {likedUser[0]?.id && ( // 存在するときのみ
          <>
            {likedUser.map((doc) => (
              <ListView
                key={doc.id}
                id={doc.id}
                uid={doc.uid}
                postId={postId || '0'}
                text=""
                createdAt={doc.createdAt}
                lastRefreshAt={doc.lastRefreshAt}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default PostLikedView;
