import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/userSlice';

import clsx from 'clsx';
import { createTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';

import Modal from '@material-ui/core/Modal'; // kamome

//import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CollectionsIcon from '@material-ui/icons/Collections';
//import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SettingsIcon from '@material-ui/icons/Settings';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';

//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
//import Avatar from "@material-ui/core/Avatar";
import { auth } from '../../firebase';

import { XROSS_ENV } from '../../xross.config.js';

import styles from './Template.module.css';

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: colors.green[800] }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
    },
    modal: {
      outline: 'none',
      position: 'absolute',
      with: 400,
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10),
    },
  })
);

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
      <Link color="inherit" to="/">
        xross.club
      </Link>{' '}
    </Typography>
  );
};

// モーダルカードの表示関数
// ログアウト用
// kamome
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();

  // redux でログインユーザの管理を行う
  const user = useSelector(selectUser);

  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false); //初期値はモーダルが閉じている状態

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(
                classes.menuButton,
                open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              xross.club 運用管理（Moon） [{XROSS_ENV}]
            </Typography>
            {/* Avatar */}
            <div>
              {user.displayName}
              <br></br>
              {user.email}
            </div>
            <Avatar src={user.photoUrl} onClick={() => setOpenModal(true)} />
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to="/" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="トップページ" />
              </ListItem>
            </Link>

            <Link to="/UserList" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="ユーザ一覧" />
              </ListItem>
            </Link>

            <Link to="/PostCollection" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary="投稿一覧" />
                <Link
                  to="/PostCollection/SinglePostView"
                  className={classes.link}
                ></Link>
              </ListItem>
            </Link>
            <Link to="/MapPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary="投稿一覧（地図）" />
                <Link
                  to="/PostCollection/SinglePostView"
                  className={classes.link}
                ></Link>
              </ListItem>
            </Link>
            <Link to="/InfoPage" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="お知らせ" />
              </ListItem>
            </Link>
            <Link to="/ShereFeed" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <CollectionsIcon />
                </ListItemIcon>
                <ListItemText primary="SNS共有ページ" />
              </ListItem>
            </Link>

            <Link to="/ServiceState" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="サービス状態" />
              </ListItem>
            </Link>

            <Link to="/Inquiry" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="問い合わせ管理" />
              </ListItem>
            </Link>

            <Link to="/FunctionLog" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="FunctionLog" />
              </ListItem>
            </Link>

            <div>
              <hr />
              ＜システム設定＞
              <hr />
            </div>

            <Link to="/SystemSetup" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="システム初期化" />
              </ListItem>
            </Link>

            <Link to="/RefreshPostsData" className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="投稿データのリフレッシュ" />
              </ListItem>
            </Link>
          </List>

          <div>
            <hr />
            ＜システムテスト用＞
            <hr />
          </div>
          <Link to="/EntrySystemTestData" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="システムテスト用データの投入" />
            </ListItem>
          </Link>

          <div>
            <hr />
            ＜テストデータ投入＞
            <hr />
          </div>
          <Link to="/EntryTestData" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="単体テスト用テストデータの投入" />
            </ListItem>
          </Link>
          <Link to="/CreatePost" className={classes.link}>
            <ListItem button>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="新規投稿" />
            </ListItem>
          </Link>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.pageTitle}
            >
              {title}
            </Typography>
            {children}
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div style={getModalStyle()} className={classes.modal}>
          <div className={styles.login_modal}>
            ログアウトにゃ！
            <IconButton
              onClick={async () => {
                await auth.signOut();
              }}
            >
              <ExitToAppIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
};

export default GenericTemplate;
