import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage';
import UserList from './pages/UserList';
import PostCollection from './pages/PostCollection';
import MapPage from './pages/MapPage';
import InfoPage from './pages/InfoPage';

import ShereFeed from './pages/ShereFeed';

import ServiceState from './pages/ServiceStatePage';
import Inquiry from './pages/InquiryPage';
import FunctionLog from './pages/FunctionLog';

import SystemSetup from './pages/SystemSetupPage';
import RefreshPostsData from './pages/RefreshPostDataPage';

import EntryTestData from './pages/EntryTestData';

import EntrySystemTestData from './pages/EntrySystemTestData';

import PostDetail from './pages/PostDetail';
import UserDetail from './pages/UserDetail';

import CreatePost from './pages/CreatePost';

const Management: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" component={HomePage} exact />

        <Route path="/CreatePost" component={CreatePost} exact />

        <Route path="/UserList" component={UserList} exact />
        <Route path="/UserDetail/:id" component={UserDetail} exact />

        <Route path="/PostCollection" component={PostCollection} exact />
        <Route path="/PostDetail/:id" component={PostDetail} exact />
        <Route path="/MapPage" component={MapPage} exact />

        <Route path="/InfoPage" component={InfoPage} exact />

        <Route path="/ShereFeed/:id" component={ShereFeed} exact />
        <Route path="/ShereFeed/" component={ShereFeed} exact />
        <Route path="/SystemSetup" component={SystemSetup} exact />
        <Route path="/RefreshPostsData" component={RefreshPostsData} exact />

        <Route
          path="/EntrySystemTestData"
          component={EntrySystemTestData}
          exact
        />

        <Route path="/EntryTestData" component={EntryTestData} exact />
        <Route path="/ServiceState" component={ServiceState} exact />
        <Route path="/Inquiry" component={Inquiry} exact />
        <Route path="/FunctionLog" component={FunctionLog} exact />
      </Switch>
    </Router>
  );
};

export default Management;
