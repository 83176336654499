// xross interface
import { XROSS_FRIEND } from '$common/interface';

// xross common functions
import {
  getFriendList,
  getFriendRequestList,
  getFriendPendingList,
} from '../../common/firebase/friend';

export type FRIENDSHIP = {
  myFriendList: XROSS_FRIEND[];
  myFriendRequestList: XROSS_FRIEND[];
  myFriendPendingList: XROSS_FRIEND[];
};

/*
 * 友達関係リスト作成
 */
export const getFriendship = async (uid: string) => {
  const friendship: FRIENDSHIP = {
    myFriendList: [],
    myFriendRequestList: [],
    myFriendPendingList: [],
  };

  /*
   * -----------------------------------------------------------------
   * 自分の友達リストを取得する
   * -----------------------------------------------------------------
   */
  try {
    if (!uid) return;
    const friends = await getFriendList(uid);
    friendship.myFriendList = friends.slice();
  } catch (e) {
    //setError(e);
  }

  /*
   * -----------------------------------------------------------------
   * 自分が友達申請中のリストを取得する
   * -----------------------------------------------------------------
   */
  try {
    if (!uid) return;
    const uidList = await getFriendRequestList(uid);
    friendship.myFriendRequestList = uidList.slice();
  } catch (e) {
    //setError(e);
  }

  /*
   * -----------------------------------------------------------------
   * 自分が友達申請中のリストを取得する
   * -----------------------------------------------------------------
   */
  try {
    if (!uid) return;
    const uidList = await getFriendPendingList(uid);
    friendship.myFriendPendingList = uidList.slice();
  } catch (e) {
    //setError(e);
  }

  return friendship;
};
