import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import { FUNCTION_LOG_LIST } from '$common/interface';

// コレクション名定義
import { FUNCTION_LOG } from '../../common/define';

/*
 * -----------------------------------------------------------------
 * Function Log 一覧を取得する
 * -----------------------------------------------------------------
 */
export const getFunctionLog = async (
  limitNum: number,
  lastDoc: FUNCTION_LOG_LIST | null,
  excludeProcessEvents: boolean = false
): Promise<FUNCTION_LOG_LIST[]> => {
  console.log(
    'getFunctionLog limitNum=',
    limitNum,
    ' lastDoc=',
    lastDoc,
    ' excludeProcessEvents=',
    excludeProcessEvents
  );
  try {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> =
      db.collection(FUNCTION_LOG);

    if (excludeProcessEvents) {
      // 'PROCESS' と 'TRACE' を除く
      const allowedEventTypes = ['START', 'END', 'ABEND', 'ERROR'];
      query = query.where('eventType', 'in', allowedEventTypes);
    }

    query = query.orderBy('createdAt', 'desc').limit(limitNum);

    if (lastDoc && lastDoc.createdAt) {
      query = query.startAfter(lastDoc.createdAt);
    }

    const ss = await query.get();
    const functionLogList = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as FUNCTION_LOG_LIST)
    );
    console.log('++++++++++ getFunctionLog 結果 ', functionLogList.length);
    return functionLogList;
  } catch (e) {
    console.log('---------- getFunctionLog error ', e);
    return [];
  }
};
