import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../features/userSlice';
import {
  Button,
  TextField,
  Paper,
  Grid,
  Modal,
  IconButton,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import GenericTemplate from '../components/templates/GenericTemplate';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from 'react-router';
// xross web page styles
import { xrossUseStyles } from '../components/pageStyles';
// xross interfaces
import { XROSS_USER_PROFILE, XROSS_POST_EX } from '../common/interface';
// view compornent
import PostDetailView from '../components/PostDetailView';
import PostDetailEdit from '../components/PostDetailEdit';
import PostLikedlView from '../components/PostLikedView';
import PostCommentView from '../components/PostCommentView';
import { putComment, onLikedPost, offLikedPost } from '../common/firebase/post';
import { getPostDetail } from '../common/firebase/post';

type IdrProps = RouteComponentProps<{
  id: string;
}>;

// モーダルカードの表示関数
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const PostDetail: React.FC<IdrProps> = (props) => {
  console.log('>>>>>>>>>>>>PostDetailView>>>>>>>>>>');
  console.log('>>PostDetailView');
  const user = useSelector(selectUser);
  const [openModal, setOpenModal] = useState(false); //初期値はモーダルが閉じている状態
  const classes = xrossUseStyles();
  const history = useHistory();

  // 引き渡されたpostId
  const postId = props.match.params.id;
  console.log('postId:' + postId);

  const [postDataSet, setPostDataSet] = useState<XROSS_POST_EX>();
  // {
  // id: postId, // ドキュメントID(postId)
  // uid: '', //firebaseのuid
  // text: ' ',
  // hashtag: [],
  // contents: [],
  // //投稿時の位置情報（表示に使用しない）
  // gLocation: {
  //   latitude: '',
  //   longitude: '',
  // },
  // //表示用位置情報（ユーザ設定が、ホームの場合はホームの位置情報、GPSの場合はGPSの位置情報）
  // displayGLocation: {
  //   latitude: '',
  //   longitude: '',
  // },
  // displayGLocationLabel: '',
  // disclosureRange: '_PUBLIC_', //公開範囲
  // postStatus: '_STORED_',
  // category: '_NORMAL_',
  // }

  // 拡張ユーザ情報を取得する
  const [xrossUserProfile, setXrossUserProfile] = useState<XROSS_USER_PROFILE>({
    id: '', // ドキュメントID(uid)
    xrossUserID: '',
    xrossUserName: '',
    xrossAvatarURL: '',
    xrossUserStatus: '',
    profile: '',
    homeGLocation: {
      latitude: 0,
      longitude: 0,
    },
    homeGLocationLabel: '',
    isDefaultLocationAtHome: true,
    defaultPostDisclosureRange: '',
    // createdAt: null,
    // lastRefreshAt: null,
  });

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const postDoc: XROSS_POST_EX | null = await getPostDetail(postId);
    if (postDoc != null) {
      console.log('getPostDetail postDoc = ', postDoc);
      // ToDo: ★★★要見直し
      postDoc.createdAt = undefined;
      postDoc.lastRefreshAt = undefined;
      setPostDataSet(postDoc);
    }
  };

  const [viewMode, setViewMode] = useState(true); // true:view mode / false :edit mode

  const [subCollectionViewMode, setSubCollectionViewMode] = useState(true);

  const changeViewMode = () => {
    viewMode ? setViewMode(false) : setViewMode(true);
  };

  const changeLikedUserView = () => {
    setSubCollectionViewMode(false);
  };

  // ユーザ用画面のシェア画面を表示する
  // 引数：postId
  const viewShereFeed = () => {
    history.push('/ShereFeed/' + postId);
  };

  const changeCommentView = () => {
    setSubCollectionViewMode(true);
  };
  const [comment, setComment] = useState('');
  const newComment = async () => {
    const uid = user.uid;
    await putComment(uid, postId, comment);
    setOpenModal(false);
    setComment('');
  };

  const onLiked = async () => {
    const uid = user.uid;
    await onLikedPost(uid, postId);
  };

  const offLiked = async () => {
    const uid = user.uid;
    await offLikedPost(uid, postId);
  };

  return (
    <GenericTemplate title="xross.club 投稿内容詳細">
      <Button variant="contained" onClick={viewShereFeed}>
        {'シェア画面を表示する'}
      </Button>
      <Grid container justify="space-around">
        <Grid item xs={4} component={Paper} elevation={6} square>
          <Button variant="contained" color="primary" onClick={changeViewMode}>
            {viewMode ? '編集する！' : '表示モード'}
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenModal(true)}
          >
            {'返信コメントする'}
          </Button>
          <Button variant="contained" color="secondary" onClick={onLiked}>
            {'いいねする'}
          </Button>

          <Button variant="contained" color="secondary" onClick={offLiked}>
            {'いいねを外す'}
          </Button>

          {viewMode ? (
            <PostDetailView postDataSet={postDataSet} />
          ) : (
            <PostDetailEdit postDataSet={postDataSet} />
          )}
        </Grid>

        <Grid item xs={4} component={Paper} elevation={6} square>
          <PostCommentView postDataSet={postDataSet} />
        </Grid>

        <Grid item xs={4} component={Paper} elevation={6} square>
          <PostLikedlView postDataSet={postDataSet} />
        </Grid>
      </Grid>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div style={getModalStyle()} className={classes.modal}>
          <div>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              name="comment"
              label="comment"
              value={comment}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setComment(e.target.value);
              }}
            />
            <IconButton onClick={newComment}>
              <SendIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    </GenericTemplate>
  );
};

export default PostDetail;
