import React, { useState, useEffect } from "react";
import { db } from "../firebase";

import GenericTemplate from "../components/templates/GenericTemplate";
import { Avatar, Button, Grid, CssBaseline } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// switch
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

//interface
import { XROSS_SERVICE_STATE } from "../common/interface";

// admin xross utils
import {
  deleteAllDcocuments,
  makeOnLikedPost,
  makeOffLikedPost,
} from "../adminUtil/adminXrossUtil";


// xross common function
import { getXrossServiceStatus } from "../common/firebase/status";
import { getXrossPostNum } from "../common/firebase/post";
import { getXrossUserNum } from "../common/firebase/user";

// xross web page styles
import { xrossUseStyles } from "../components/pageStyles";

const SystemSetup: React.FC = () => {
  console.log("SystemSetup");
  //const classes = useStyles();
  const classes = xrossUseStyles();

  const [xrossServiceState, setXrossServiceState] =
    useState<XROSS_SERVICE_STATE>({
      id: "0", // ドキュメントID
      isOutOfService: false,
      isNewUserUnavailable: false,
      isNewPostUnavailable: false,
      createdAt: null,
      lastRefreshAt: null,
    });

  const [userNum, setUserNum] = useState<number>(0);
  const [postNum, setPostNum] = useState<number>(0);

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const status = await getXrossServiceStatus();
    if (status) {
      setXrossServiceState(status);
    }
    const userNum = await getXrossUserNum();
    if (userNum) {
      setUserNum(userNum);
    }
    const postNum = await getXrossPostNum();
    if (postNum) {
      setPostNum(postNum);
    }
  };

  const deleteUsers = () => {
    deleteAllDcocuments("xrossUser");
  };

  const deletePosts = () => {
    deleteAllDcocuments("posts");
  };

  const onLiked = async () => {
    const postId = "3YQF1QNeElAOFRjN0ES9";
    await makeOnLikedPost(postId);
  };

  const offLiked = async () => {
    const postId = "3YQF1QNeElAOFRjN0ES9";
    await makeOffLikedPost(postId);
  };

  return (
    <GenericTemplate title="xross.club システム初期化">
      <Grid container spacing={2}>
        <Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>システム状態</TableCell>
                  <TableCell>新規ユーザ登録状態</TableCell>
                  <TableCell>新規投稿状態</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow key={xrossServiceState.id}>
                  <TableCell component="th" scope="row">
                    {xrossServiceState.isOutOfService ? "停止中" : "稼働中"}
                  </TableCell>
                  <TableCell>
                    {xrossServiceState.isNewUserUnavailable
                      ? "停止中"
                      : "稼働中"}
                  </TableCell>
                  <TableCell>
                    {xrossServiceState.isNewPostUnavailable
                      ? "停止中"
                      : "稼働中"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>ユーザ登録数</TableCell>
                  <TableCell>投稿数</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow key={xrossServiceState.id}>
                  <TableCell component="th" scope="row">
                    {userNum}
                  </TableCell>
                  <TableCell>{postNum}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/*         <Grid container spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={xrossServiceState.isOutOfService} />}
              label="システム停止"
            />
            <FormControlLabel
              control={
                <Switch checked={xrossServiceState.isNewUserUnavailable} />
              }
              label="新規ユーザ登録禁止"
            />
            <FormControlLabel
              control={
                <Switch checked={xrossServiceState.isNewPostUnavailable} />
              }
              label="新規投稿登録禁止"
            />
          </FormGroup>
        </Grid> */}
      </Grid>
    </GenericTemplate>
  );
};
export default SystemSetup;
