import React, { useState, useEffect } from 'react';

import GenericTemplate from '../components/templates/GenericTemplate';
import { Avatar, Button, Grid, CssBaseline } from '@material-ui/core';

//interface
import { XROSS_SERVICE_STATE, XROSS_FRIEND } from '../common/interface';

// admin xross utils
import {
  refreshPostsByUID,
  addDeliveryList,
  delDeliveryList,
  onUpdateUser,
} from '../adminUtil/tools/refreshPosts';

import {
  onChangePostsStatusBanByUID,
  onChangePostsStatusRestoreByUID,
} from '../adminUtil/tools/onChangePostsStatus';

// xross web page styles

import { TextField } from '@mui/material';

const RefreshPostsData: React.FC = () => {
  console.log('SystemSetup');

  const [actionUid, setActionUid] = useState('');
  const [targetUid, setTargetUid] = useState('');

  const [avatar, setAvatar] = useState('');
  const [userName, setUserName] = useState('');

  //　配送先を更新する
  const refreshPosts = async () => {
    if (targetUid === '') return;
    console.log('投稿データの配送先をリフレッシュする ', targetUid);
    await refreshPostsByUID(targetUid);
  };

  // BANにする
  const onChangePostsToBan = async () => {
    if (targetUid === '') return;
    console.log('投稿データのstatusをBANにする ', targetUid);
    await onChangePostsStatusBanByUID(targetUid);
  };

  // BANから復活する
  const onChangePostsToRestore = async () => {
    if (targetUid === '') return;
    console.log('投稿データのstatusをBANから復活する ', targetUid);
    await onChangePostsStatusRestoreByUID(targetUid);
  };

  // 指定したUIDをdeliveryUIDList に追加する
  const onAddDeliveryList = async () => {
    if (actionUid === '') return;
    if (targetUid === '') return;

    console.log(
      '指定したUIDをdeliveryUIDList に追加する a=',
      actionUid,
      ' t=',
      targetUid
    );
    await addDeliveryList(actionUid, targetUid);
  };

  // 指定したUIDをdeliveryUIDList から削除する
  const onDelDeliveryList = async () => {
    if (actionUid === '') return;
    if (targetUid === '') return;

    console.log(
      '指定したUIDをdeliveryUIDList に追加する a=',
      actionUid,
      ' t=',
      targetUid
    );
    await delDeliveryList(actionUid, targetUid);
  };

  // 指定したUIDが投稿したデータのアバターと名前を変更する
  const onModifyUser = async () => {
    if (targetUid === '') return;
    console.log('avatar=', avatar);
    console.log('userName=', userName);
    await onUpdateUser(targetUid, avatar, userName);
  };

  return (
    <GenericTemplate title="xross.club 投稿データのリフレッシュ">
      <div>配送先の更新</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="friendFrom"
          type="text"
          label="UID"
          autoComplete="friendFrom"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <Button variant="contained" color="secondary" onClick={refreshPosts}>
          投稿データの配送先をリフレッシュする
        </Button>
      </Grid>
      <hr></hr>
      <div>UIDを指定してBANにする</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="friendFrom"
          type="text"
          label="UID"
          autoComplete="friendFrom"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={onChangePostsToBan}
        >
          投稿データのstatusをBANにする
        </Button>
      </Grid>

      <hr></hr>
      <div>UIDを指定してBANから復活する</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="friendFrom"
          type="text"
          label="UID"
          autoComplete="friendFrom"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={onChangePostsToRestore}
        >
          投稿データのstatusをBANから復活する
        </Button>
      </Grid>

      <hr></hr>
      <div>指定したUIDをdeliveryUIDList に追加する（友達になった時）</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="actionUid"
          type="text"
          label="actionUid"
          autoComplete="actionUid"
          value={actionUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setActionUid(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="targetUid"
          type="text"
          label="targetUid"
          autoComplete="targetUid"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={onAddDeliveryList}
        >
          指定したUIDをdeliveryUIDList に追加する
        </Button>
      </Grid>

      <hr></hr>
      <div>指定したUIDをdeliveryUIDList から削除する（友達を破棄したとき）</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="actionUid"
          type="text"
          label="actionUid"
          autoComplete="actionUid"
          value={actionUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setActionUid(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="targetUid"
          type="text"
          label="targetUid"
          autoComplete="targetUid"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={onDelDeliveryList}
        >
          指定したUIDをdeliveryUIDList から削除する
        </Button>
      </Grid>

      <hr></hr>
      <div>指定したUIDのアバターと名前を変更する</div>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="targetUid"
          type="text"
          label="targetUid"
          autoComplete="targetUid"
          value={targetUid}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setTargetUid(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="avatar"
          type="text"
          label="avatar"
          autoComplete="avatar"
          value={avatar}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setAvatar(e.target.value);
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="userName"
          type="text"
          label="userName"
          autoComplete="userName"
          value={userName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUserName(e.target.value);
          }}
        />
        <Button variant="contained" color="secondary" onClick={onModifyUser}>
          指定したUIDが投稿したデータのアバターと名前を変更する
        </Button>
      </Grid>
    </GenericTemplate>
  );
};
export default RefreshPostsData;
