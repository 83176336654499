import React, { useState, useEffect } from 'react';

import GenericTemplate from '../components/templates/GenericTemplate';
import { Button, Grid } from '@material-ui/core';

//interface
import { XROSS_SERVICE_STATE } from '../common/interface';

import {
  XROSS_MESSAGE,
  XROSS_GROUP_TALK_ROOM,
  XROSS_TALK_ROOM,
  XROSS_TALK,
  XROSS_TALK_HISTORY,
} from '../common/interface';

import { XROSS_CLIP, XROSS_CLIP_FOLDER } from '../common/interface';

// admin xross utils
import {
  deleteAllDcocuments,
  makeOnLikedPost,
  makeOffLikedPost,
} from '../adminUtil/adminXrossUtil';
import { makeTestUserData } from '../adminUtil/TestDataFactory/makeUser';

import { makeTestPostData } from '../adminUtil/TestDataFactory/makePostData';

// xross common function
import {
  requestFriendTo,
  rejectFriendRequest,
  cancelFriendRequest,
  applyFriendRequest,
  brokeFriendTo,
} from '../common/firebase/friend';

import { getXrossPostNum } from '../common/firebase/post';
import { getXrossServiceStatus } from '../common/firebase/status';
import { getXrossUserNum } from '../common/firebase/user';

// message
import {
  setMessageRoom,
  setMessageRoomName,
  getMessageHistory,
  getMessageRoomList,
  getMessageRoomInfo,
  getMessageList,
  setMessage,
  deleteMessage,
  editMessage,
} from '../common/firebase/message';

// collection
import {
  addClip,
  removeClip,
  makeClipFolder,
  removeClipFolder,
  addClipToFolder,
  removeClipFromFrolder,
  getClipList,
  getClipFolderList,
  getClipFolderInfo,
  hasCollection,
} from '../common/firebase/collection';

// stamp
import { getMyStampInfo, getBuiltinStampInfo } from '../common/firebase/stamp';

// xross web page styles
import { xrossUseStyles } from '../components/pageStyles';
import { TextField } from '@mui/material';

const EntryTestData: React.FC = () => {
  console.log('SystemSetup');
  //const classes = useStyles();
  const classes = xrossUseStyles();

  const [xrossServiceState, setXrossServiceState] =
    useState<XROSS_SERVICE_STATE>({
      id: '0', // ドキュメントID
      isOutOfService: false,
      isNewUserUnavailable: false,
      isNewPostUnavailable: false,
      // createdAt: null,
      // lastRefreshAt: null,
    });

  const [userNum, setUserNum] = useState<number>(0);
  const [postNum, setPostNum] = useState<number>(0);

  const [friendTo, setFriendTo] = useState<string>('');
  const [friendFrom, setFriendFrom] = useState<string>('');

  const [myUid, setMyUid] = useState<string>('');
  const [target, setTarget] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [name, setName] = useState<string>('');
  //const [xrossMessage, setXrossMessage] = useState<XROSS_TALK>('');

  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const status = await getXrossServiceStatus();
    if (status) {
      setXrossServiceState(status);
    }
    const userNum = await getXrossUserNum();
    if (userNum) {
      setUserNum(userNum);
    }
    const postNum = await getXrossPostNum();
    if (postNum) {
      setPostNum(postNum);
    }
  };

  const deleteUsers = () => {
    deleteAllDcocuments('xrossUser');
  };

  const deletePosts = () => {
    deleteAllDcocuments('posts');
  };

  const onLiked = async () => {
    const postId = '3YQF1QNeElAOFRjN0ES9';
    await makeOnLikedPost(postId);
  };

  const offLiked = async () => {
    const postId = '3YQF1QNeElAOFRjN0ES9';
    await makeOffLikedPost(postId);
  };

  const requestFriend = async () => {
    await requestFriendTo(friendFrom, friendTo);
  };

  const rejectFriend = async () => {
    await rejectFriendRequest(myUid, target);
  };
  const cancelFriend = async () => {
    await cancelFriendRequest(friendFrom, friendTo);
  };

  const applyFriend = async () => {
    await applyFriendRequest(myUid, target);
  };

  const brokeFriend = async () => {
    await brokeFriendTo(myUid, target);
  };

  //
  //
  // message
  //
  //
  const testSetMessageGroup = async () => {
    await setMessageRoom(myUid, target);
  };

  const testSetMessageRoomName = async () => {
    await setMessageRoomName(myUid, id, name);
  };

  const testSetMessage = async () => {
    const xrossMessage: XROSS_TALK = {
      text: name,
      image: [],
      stamp: [],
      fromUid: '',
      sendUserName: '',
      sendAvatar: '',
      recvUserName: '',
      recvAvatar: '',
      createdAt: new Date(),
      lastRefreshAt: new Date(),
      deleteFlag: false,
    };

    await setMessage(myUid, id, xrossMessage);
  };

  const testGetMessageHistory = async () => {
    const ret = await getMessageHistory(myUid);
    console.log('getMessageHistory ==========');
    console.log('getMessageHistory uid =', myUid);
    console.log('getMessageHistory ret =', ret);
  };

  const testGetMessageRoomList = async () => {
    const ret = await getMessageRoomList(myUid);
    console.log('getMessageRoomList ==========');
    console.log('getMessageRoomList uid =', myUid);
    console.log('getMessageRoomList ret =', ret);
  };

  const testGetMessageRoomInfo = async () => {
    const ret = await getMessageRoomInfo(myUid, id);
    console.log('getMessageRoomInfo ==========');
    console.log('getMessageRoomInfo uid =', myUid, ' id = ', id);
    console.log('getMessageRoomInfo ret =', ret);
  };

  const testGetMessageList = async () => {
    const ret = await getMessageList(myUid, id);
    console.log('getMessageList ==========');
    console.log('getMessageList uid =', myUid, ' id = ', id);
    console.log('getMessageList ret =', ret);
  };

  //
  //
  // collection
  //
  //
  const testAddClip = async () => {
    await addClip(myUid, id);
    console.log('addClip ==========');
    console.log('addClip uid =', myUid, ' postId = ', id);
  };

  const testRemoveClip = async () => {
    await removeClip(myUid, id);
    console.log('removeClip ==========');
    console.log('removeClip uid =', myUid, ' postId = ', id);
  };

  const testMakeClipFolder = async () => {
    await makeClipFolder(myUid, name);
    console.log('makeClipFolder ==========');
    console.log('makeClipFolder uid =', myUid, ' フォルダ名 = ', name);
  };

  const testRemoveClipFolder = async () => {
    await removeClipFolder(myUid, id);
    console.log('removeClipFolder ==========');
    console.log('removeClipFolder uid =', myUid, ' フォルダID = ', id);
  };

  const testAddClipToFolder = async () => {
    await addClipToFolder(myUid, name, id);
    console.log('addClipToFolder ==========');
    console.log(
      'addClipToFolder uid =',
      myUid,
      'ref= ',
      name,
      ' フォルダID = ',
      id
    );
  };

  const testRemoveClipFromFrolder = async () => {
    await removeClipFromFrolder(myUid, name, id);
    console.log('addClipToFolder ==========');
    console.log(
      'addClipToFolder uid =',
      myUid,
      'ref= ',
      name,
      ' フォルダID = ',
      id
    );
  };

  const testGetClipList = async () => {
    const List = await getClipList(myUid);
    console.log('getClipList ==========');
    console.log('getClipList uid =', myUid);
    console.log('getClipList List =', List);
  };

  const testGetClipFolderList = async () => {
    const List = await getClipFolderList(myUid);
    console.log('getClipFolderList ==========');
    console.log('getClipFolderList uid =', myUid);
    console.log('getClipFolderList List =', List);
  };

  const testGetClipFolderInfo = async () => {
    const info = await getClipFolderInfo(myUid, id);
    console.log('getClipFolderInfo ==========');
    console.log('getClipFolderInfo uid =', myUid, ' フォルダID =', id);
    console.log('getClipFolderInfo info =', info);
  };

  const testHasCollection = async () => {
    const info = await hasCollection(myUid, id);
    console.log('hasCollection ==========');
    console.log('hasCollection uid =', myUid, ' postId =', id);
    console.log('hasCollection 投稿内容 =', info);
  };

  const testGetMyStampInfo = async () => {
    const info = await getMyStampInfo(myUid);
    console.log('getMyStampInfo ==========');
    console.log('getMyStampInfo uid =', myUid);
    console.log('getMyStampInfo スタンプ情報 =', info);
  };

  const testGetBuiltinStampInfo = async () => {
    const info = await getBuiltinStampInfo();
    console.log('getBuiltinStampInfo ==========');
    console.log('getBuiltinStampInfo uid =', myUid);
    console.log('getBuiltinStampInfo スタンプ情報 =', info);
  };

  return (
    <GenericTemplate title="xross.club テストデータ投入">
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={makeTestUserData}
          >
            ユーザを作成する
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary" onClick={deleteUsers}>
            全ユーザを削除する
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="primary" onClick={deletePosts}>
            全投稿データを削除する
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="primary" onClick={onLiked}>
            100人のいいね
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <Button variant="contained" color="primary" onClick={offLiked}>
            いいねを取り消す
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="friendFrom"
            type="text"
            label="friendFrom"
            autoComplete="friendFrom"
            value={friendFrom}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFriendFrom(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="friendTo"
            type="text"
            label="friendTo"
            autoComplete="friendTo"
            value={friendTo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFriendTo(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={requestFriend}>
            友達申請
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="delete-target"
            type="text"
            label="delete-target"
            autoComplete="delete-target"
            value={target}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTarget(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={rejectFriend}>
            友達申請を却下する
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="delete-target"
            type="text"
            label="delete-target"
            autoComplete="delete-target"
            value={friendTo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFriendTo(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={friendFrom}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFriendFrom(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={cancelFriend}>
            友達申請を取りやめる
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="target"
            type="text"
            label="target"
            autoComplete="target"
            value={target}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTarget(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={applyFriend}>
            友達申請を許可する
          </Button>
        </Grid>

        <Grid item xs={6} md={4}>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="target"
            type="text"
            label="target"
            autoComplete="target"
            value={target}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTarget(e.target.value);
            }}
          />
          <Button variant="contained" color="primary" onClick={brokeFriend}>
            友達関係を破棄する
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            onClick={makeTestPostData}
          >
            ダミー投稿（位置情報付き50件）
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary">
            ＃＃＃
          </Button>
        </Grid>
        <Grid item xs={6} md={4}>
          <Button variant="contained" color="secondary">
            ＃＃＃
          </Button>
        </Grid>
      </Grid>
      <p />
      <div>
        <h2>message test</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <h3>(1) setMessageGroup</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="target"
            type="text"
            label="target"
            autoComplete="target"
            value={target}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setTarget(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testSetMessageGroup}
          >
            メッセージグループを設定する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3>(2) setMessageRoomName</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="id"
            autoComplete="id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            type="text"
            label="name"
            autoComplete="name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testSetMessageRoomName}
          >
            ルーム名を設定する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (7) setMessage</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="id"
            autoComplete="id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            type="text"
            label="name"
            autoComplete="name"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testSetMessage}
          >
            メッセージを投稿する
          </Button>
        </Grid>
        {/* */}
        <Grid item xs={2}>
          <h3> (3) getMessageHistory</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetMessageHistory}
          >
            直近のメッセージリストを取得する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (4) getMessageRoomList</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetMessageRoomList}
          >
            ルームリストを取得する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (5) getMessageRoomInfo</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="room id"
            autoComplete="room id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testGetMessageRoomInfo}
          >
            ルームの情報を取得する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (6) getMessageList</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="room id"
            autoComplete="room id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testGetMessageList}
          >
            ルーム内のメッセージリストを取得する
          </Button>
        </Grid>
        {/* */}
      </Grid>
      <p />
      <div>
        <h2>collection test</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <h3>(1) addClip</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="postId"
            autoComplete="id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />

          <Button variant="contained" color="secondary" onClick={testAddClip}>
            投稿記事を保存する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3>(2) removeClip</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="id"
            type="text"
            label="postId"
            autoComplete="id"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testRemoveClip}
          >
            投稿記事を保存から削除する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (3) makeClipFolder</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="folderName"
            type="text"
            label="folderName"
            autoComplete="folderName"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testMakeClipFolder}
          >
            保存フォルダーを作成する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (4) removeClipFolder</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="folderId"
            type="text"
            label="folderId"
            autoComplete="folderId"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testRemoveClipFolder}
          >
            保存フォルダーを削除する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (5) addClipToFolder</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="docRef"
            type="text"
            label="docRef"
            autoComplete="docRef"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="folderId"
            type="text"
            label="folderId"
            autoComplete="folderId"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testAddClipToFolder}
          >
            保存した投稿記事をフォルダに入れる
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (6) removeClipFromFrolder</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="docRef"
            type="text"
            label="docRef"
            autoComplete="docRef"
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="folderId"
            type="text"
            label="folderId"
            autoComplete="folderId"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testRemoveClipFromFrolder}
          >
            フォルダに保存した投稿記事を削除する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (7) getClipList</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetClipList}
          >
            保存した投稿記事リストを取得する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3> (8) getClipFolderList</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetClipFolderList}
          >
            保存したフォルダリストを取得する
          </Button>
        </Grid>
        <Grid item xs={2}>
          <h3> (8)-1 hasCollection</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="uid"
            type="text"
            label="uid"
            autoComplete="uid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="postId"
            type="text"
            label="postId"
            autoComplete="postId"
            value={id}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setId(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={testHasCollection}
          >
            postIdが CLIP 内に存在するか
          </Button>
        </Grid>
        {/* */}
      </Grid>

      <p />
      <div>
        <h2>stamp test</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <h3>(1) getMyStampInfo</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetMyStampInfo}
          >
            自分が保有するスタンプ情報を取得する
          </Button>
        </Grid>

        <Grid item xs={2}>
          <h3>(2) getBuiltinStampInfo</h3>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="myUid"
            type="text"
            label="myUid"
            autoComplete="myUid"
            value={myUid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setMyUid(e.target.value);
            }}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={testGetBuiltinStampInfo}
          >
            予め保有するスタンプ情報を取得する
          </Button>
        </Grid>

        {/* */}
      </Grid>
    </GenericTemplate>
  );
};
export default EntryTestData;
