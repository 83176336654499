import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import firebase from 'firebase/app';

// import { onLikedPost, offLikedPost } from "../../common/firebase/post";

// import { createPost } from "../../common/firebase/post";

// xross interfaces
import { XROSS_USER_PROFILE } from '../../common/interface';

// sample data
// import { locationSample } from "../TestDataStore/postDataSet";
// import { uri } from "../TestDataStore/UriDataSet";
import { user10000 as userDataSet } from '../TestDataStore/STUserDataSet';

const USER_NUM = 10000;
// const USER_NUM = 10;

// 10000件のユーザ登録
export const makeTestUserData = (pref: string) => {
  console.log('user10000 データ取り込み');
  (async () => {
    try {
      for (let i = 1; i < USER_NUM + 1; i++) {
        // const timestamp = new Date();

        const user: XROSS_USER_PROFILE = {
          // id: userDataSet[i][0],
          id: pref + i,
          // xrossUserID: userDataSet[i][1],
          xrossUserID: pref + i,
          xrossUserName: userDataSet[i][2],
          xrossAvatarURL: userDataSet[i][3],
          xrossUserStatus: '_ACTIVE_',
          profile: userDataSet[i][4],
          homeGLocation: {
            latitude: 0,
            longitude: 0,
          },
          homeGLocationLabel: userDataSet[i][5],
          isDefaultLocationAtHome: true,
          defaultPostDisclosureRange: '_PUBLIC_',
          // createdAt: timestamp,
          // lastRefreshAt: timestamp,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
          // createdAt: {
          //   seconds: 0,
          //   nanoseconds: 0,
          // },
          // lastRefreshAt: {
          //   seconds: 0,
          //   nanoseconds: 0,
          // },
        };
        console.log('# ', i);
        console.log('user10000 user=', user);

        await db
          .collection('xrossUser')
          .doc(String(pref + i))
          .set(user);
      }
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
  })();
};
