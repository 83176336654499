import React, { useEffect, useState } from 'react';
import GenericTemplate from '../components/templates/GenericTemplate';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Modal from '@material-ui/core/Modal'; // kamome
import IconButton from '@material-ui/core/IconButton';
import styles from '../components/templates/Template.module.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

//import { GetXrossServiceStatus } from "../common/GetXrossServiceStatus2";

// xross common function
import {
  getXrossServiceStatus,
  setOutOfService,
} from '../common/firebase/status';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    modal: {
      outline: 'none',
      position: 'absolute',
      with: 400,
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10),
    },
  })
);

// モーダルカードの表示関数
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const ServiceState: React.FC = () => {
  const classes = useStyles();
  const [xrossServiceState, setXrossServiceState] = useState({
    id: '', // ドキュメントID
    isOutOfService: false,
    isNewUserUnavailable: false,
    isNewPostUnavailable: false,
    // createdAt: null,
    // lastRefreshAt: null,
  });
  const [maintenance, setMaintenance] = useState(false);
  useEffect(() => {
    getFirebaseItems();
  }, []);

  const getFirebaseItems = async () => {
    const status = await getXrossServiceStatus();
    if (status) {
      setXrossServiceState(status);
      setOutOfService(status.isOutOfService);
    }
  };

  const onCahngeOutOfService = async () => {
    console.log('switch onCahngeOutOfService');
    setOpenModal(false);
    await setOutOfService(!maintenance);
    setMaintenance(!maintenance);
    await getFirebaseItems();
  };
  const [openModal, setOpenModal] = useState(false); //初期値はモーダルが閉じている状態

  return (
    <GenericTemplate title="xross.club サービス状態">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>システム状態</TableCell>
              <TableCell>新規ユーザ登録状態</TableCell>
              <TableCell>新規投稿状態</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key={xrossServiceState.id}>
              <TableCell component="th" scope="row">
                {xrossServiceState.isOutOfService ? '停止中' : '稼働中'}
              </TableCell>
              <TableCell>
                {xrossServiceState.isNewUserUnavailable ? '停止中' : '稼働中'}
              </TableCell>
              <TableCell>
                {xrossServiceState.isNewPostUnavailable ? '停止中' : '稼働中'}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div>サービスを停止する</div>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch checked={maintenance} onChange={() => setOpenModal(true)} />
          }
          label="メンテナンス中"
        />
      </FormGroup>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div style={getModalStyle()} className={classes.modal}>
          <div className={styles.login_modal}>
            {maintenance
              ? 'サービスを再開しますか？'
              : '本当にサービスを停止しますか？'}
            <IconButton onClick={onCahngeOutOfService}>
              <ExitToAppIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
    </GenericTemplate>
  );
};
export default ServiceState;
