import React, { useEffect, useState } from 'react';
import GenericTemplate from '../components/templates/GenericTemplate';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';

// xross common function
import { getFunctionLog } from '../admin/firebase/functionLog';

// xross interface
import { FUNCTION_LOG_LIST } from '../common/interface';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    modal: {
      outline: 'none',
      position: 'absolute',
      width: 400,
      borderRadius: 10,
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(10),
    },
  })
);

const FunctionLog: React.FC = () => {
  const classes = useStyles();
  const [functionLog, setFunctionLog] = useState<FUNCTION_LOG_LIST[]>([]);
  const [lastDoc, setLastDoc] = useState<FUNCTION_LOG_LIST | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [excludeProcessEvents, setExcludeProcessEvents] = useState(true);

  const MAX_READ = 1000;

  useEffect(() => {
    setLastDoc(null);
    setHasMore(true);
    getInitialFirebaseItems();
  }, [excludeProcessEvents]);

  const getInitialFirebaseItems = async () => {
    setIsLoading(true);

    const list: FUNCTION_LOG_LIST[] = await getFunctionLog(
      MAX_READ,
      null,
      excludeProcessEvents
    );

    setFunctionLog(list);
    setLastDoc(list.length > 0 ? list[list.length - 1] : null);
    setHasMore(list.length === MAX_READ);
    setIsLoading(false);
  };

  const getMoreFirebaseItems = async () => {
    if (!hasMore || isLoading) return;

    setIsLoading(true);
    const list: FUNCTION_LOG_LIST[] = await getFunctionLog(MAX_READ, lastDoc);

    if (list.length === 0) {
      setHasMore(false);
    } else {
      setFunctionLog((prevLogs) => [...prevLogs, ...list]);
      setLastDoc(list[list.length - 1]);
      setHasMore(list.length === MAX_READ);
    }
    setIsLoading(false);
  };

  // トグルボタンのハンドラー
  const handleExcludeProcessToggle = () => {
    setExcludeProcessEvents(!excludeProcessEvents);
  };

  return (
    <GenericTemplate title="FunctionLog">
      <div>excludeProcessEvents= {excludeProcessEvents.toString()}</div>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleExcludeProcessToggle}
      >
        {excludeProcessEvents
          ? 'すべてのイベントを表示'
          : 'PROCESSイベントを除外'}
      </Button>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              <TableCell>日時</TableCell>
              <TableCell>transactionID</TableCell>
              <TableCell>level</TableCell>
              <TableCell>eventType</TableCell>
              <TableCell>message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {functionLog.map((log, idx) => (
              <TableRow
                key={log.id}
                hover
                // onClick={(event) => handleClick(inquiry.uid)}
              >
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  {log.createdAt?.toDate().toLocaleString()}
                </TableCell>
                <TableCell>{log.transactionID}</TableCell>
                <TableCell>{log.level}</TableCell>

                <TableCell
                  style={{
                    backgroundColor:
                      log.eventType === 'START' || log.eventType === 'END'
                        ? 'lightgreen'
                        : log.eventType === 'ERROR'
                        ? 'lightcoral'
                        : 'inherit',
                  }}
                >
                  {log.eventType}
                </TableCell>
                <TableCell>{log.message}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        color="primary"
        onClick={getMoreFirebaseItems}
        disabled={!hasMore || isLoading}
      >
        {isLoading
          ? '読み込み中...'
          : hasMore
          ? '次のLIST'
          : 'これ以上のデータはありません'}
      </Button>
    </GenericTemplate>
  );
};

export default FunctionLog;
