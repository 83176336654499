import { db } from '../../firebase';
import firebase from 'firebase/app';

// xross interface
import { XROSS_STAMP_LIST, XROSS_STAMP } from '../interface';

// コレクション名定義
import {
  CL_USER,
  CL_STAMP,
  SCL_MY_STAMP,
  SCL_BUILDIN_STAMP,
  CL_DEFAULT,
} from '../define';

//
// 自分が保有するスタンプ情報を取得する
//
export const getMyStampInfo = async (uid: string) => {
  console.log('getMyStampInfo>> called');

  try {
    const snapshot = await db
      .collection(CL_USER)
      .doc(uid)
      .collection(SCL_MY_STAMP)
      .orderBy('createdAt', 'desc')
      .get();
    const MyStamplist = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_STAMP_LIST)
    );

    let list: XROSS_STAMP[] = [];
    for (let i = 0; i < MyStamplist.length; i++) {
      const stamp = await getStampInfo(MyStamplist[i].stampId);
      if (stamp != null) {
        list.push(stamp);
      }
    }

    return list;
  } catch (err) {
    console.log('getMyStampInfo>> ', err);
    return [];
  }
};

//
// 全ユーザに配布するスタンプ情報を取得する
// buildin stamps
//
export const getBuiltinStampInfo = async () => {
  console.log('getBuiltinStampInfo>> called');
  try {
    const snapshot = await db
      .collection(CL_DEFAULT)
      .doc('stamp')
      .collection(SCL_BUILDIN_STAMP)
      .orderBy('createdAt', 'desc')
      .get();
    const BuiltinStamplist = snapshot.docs.map(
      (doc) => ({ ...doc.data() } as XROSS_STAMP_LIST)
    );
    let list: XROSS_STAMP[] = [];
    for (let i = 0; i < BuiltinStamplist.length; i++) {
      const stamp = await getStampInfo(BuiltinStamplist[i].stampId);
      if (stamp != null) {
        list.push(stamp);
      }
    }
    return list;
  } catch (err) {
    console.log('getBuiltinStampInfo>> ', err);
    return [];
  }
};

// stamp IDから、stamp 情報を取得する
export const getStampInfo = async (stampId: string) => {
  console.log('getStampInfo>> called', ' stampId = ', stampId);
  try {
    const snapshot = await db.collection(CL_STAMP).doc(stampId).get();
    const stamp = snapshot.data() as XROSS_STAMP;
    return stamp;
  } catch (err) {
    console.log('getStampInfo>> ', err);
    return null;
  }
};
