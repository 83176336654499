import { db } from '../../firebase';

// xross interface
import { XROSS_INQUIRY } from '../interface';

const CL_INQUIRY = 'inquiry';
/*
 * -----------------------------------------------------------------
 * filter を指定して投稿一覧を取得する
 * -----------------------------------------------------------------
 */
export const getInquiryListByFilter = async (
  limitNum: number,
  lastData: any,
  filter?: Record<string, any> // 複数の検索条件を持つオブジェクトを追加
): Promise<XROSS_INQUIRY[]> => {
  console.log(
    'getInquiryListByFilter filter=',
    filter,
    'limitNum=',
    limitNum,
    ' lastData=',
    lastData
  );
  try {
    let query = db.collection(CL_INQUIRY);
    // searchCriteria オブジェクトに基づいて検索条件を動的に追加
    // for (const key in filter) {
    //   if (filter.hasOwnProperty(key)) {
    //     query = query.where(key, '==', filter[key]);
    //   }
    // }

    const ss = await query
      .orderBy('createdAt', 'desc')
      .startAfter(lastData)
      .limit(limitNum)
      .get();

    const list = ss.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as XROSS_INQUIRY)
    );

    return list;
  } catch (e) {
    console.log('---------- getInquiryListByFilter error ', e);
  }
  return [];
};
