import React, { useState, useEffect } from 'react';
import GenericTemplate from '../components/templates/GenericTemplate';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import styles from '../components/Post.module.css';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router';
import { Button } from '@material-ui/core';
import { Avatar } from '@material-ui/core';

// xross interfaces
import { XROSS_POST_EX } from '../common/interface';

// Admin用関数群
import { getPostAll } from '../adminUtil/adminXrossUtil';

// xross admin
import { getPostList } from '../admin/firebase/post';
// import { getPostListByFilter } from '../admin/firebase/post';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// xross.club 運営管理画面
// 投稿一覧を表示する
// 表示コンポーネントは、PostList.tsx を使用する。
const PostCollection: React.FC = () => {
  console.log('PostCollection');

  const classes = useStyles();
  const [posts, setPosts] = useState<XROSS_POST_EX[]>([]);
  const [nextList, setNextList] = useState<any>(new Date());
  const [prevLists, setPrevLists] = useState<any>([]); // 前のページのリストを記録する配列
  const [idxOffset, setIdxOffset] = useState<any>([0]); // インデックスを記録する配列
  const [searchUID, setSearchUID] = useState(''); // UIDを入力するためのステート
  const [isLoading, setIsLoading] = useState(false);

  const [listNum, setListNum] = useState(1000);
  const [byPage, setByPage] = useState(true);

  useEffect(() => {
    console.log('uid=', searchUID);
    setPosts([]);
    setNextList(new Date());
    getFirebaseItems();
  }, [searchUID]);

  /*
   * -----------------------------------------------------------------
   * 初期データを取得する
   * -----------------------------------------------------------------
   */
  const getFirebaseItems = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // firebaseからデータを取得する前に現在のページの先頭を記録する
    setPrevLists([null]); // 現在のページを履歴に追加

    const list = await getPostList(listNum, null, searchUID);
    setPosts([...list]);

    console.log('idxOffset ', idxOffset, ' +', list.length);
    setIdxOffset([...idxOffset, list.length]);

    if (list.length > 0) {
      const latest = list[list.length - 1];
      if (latest !== undefined) setNextList(latest);
    }
    setIsLoading(false);
  };

  /*
   * -----------------------------------------------------------------
   * 次のデータを取得する
   * -----------------------------------------------------------------
   */
  const handleNextPage = async () => {
    if (isLoading) return;
    setIsLoading(true);
    // firebaseからデータを取得する前に現在のページの先頭を記録する
    setPrevLists([...prevLists, nextList]); // 現在のページを履歴に追加
    // console.log('prevLists=', prevLists, ' +', nextList);

    const list = await getPostList(listNum, nextList, searchUID);

    if (byPage) {
      // 取得したページのみ表示する
      setPosts(list);

      console.log('idxOffset ', idxOffset, ' +', list.length);
      setIdxOffset([
        ...idxOffset,
        idxOffset[idxOffset.length - 1] + list.length,
      ]);
    } else {
      // list に続いて表示する
      setPosts([...posts, ...list]);
    }
    if (list.length > 0) {
      const latest = list[list.length - 1];
      if (latest !== undefined) setNextList(latest);
    }
    setIsLoading(false);
  };

  /*
   * -----------------------------------------------------------------
   * 前のデータを取得する
   * -----------------------------------------------------------------
   */
  const handlePrevPage = async () => {
    if (isLoading || prevLists.length === 0) return; // prevLists が空の場合は処理しない

    setIsLoading(true);

    const prevList = [...prevLists]; // コピーを作成する
    prevList.pop(); // 最後のページ履歴を取得し、それを削除
    // 2番目から最後の要素を取得する
    const prevPage = prevList[prevList.length - 2];
    setPrevLists(prevList); // 削除した後の履歴を更新

    const list = await getPostList(listNum, prevPage, searchUID);
    idxOffset.pop(); // 最後のページ履歴を取得し、それを削除

    // 取得したページのみ表示する
    setPosts(list);

    if (list.length > 0) {
      const latest = list[list.length - 1];
      if (latest !== undefined) setNextList(latest);
    }
    setIsLoading(false);
  };

  const history = useHistory();
  const handleClick = (id: string) => {
    console.log('PostList ->' + id);
    history.push('/PostDetail/' + id);
    // window.open(`/PostDetail/${id}`, '_blank');
  };

  const handleSearch = async () => {
    // 以降のコードは変更しない
    // ...

    // ドキュメントの再取得
    await getFirebaseItems();
  };

  const deliveryUIDListView = (deliveryUIDList: string[]) => {
    let view = '';
    if (deliveryUIDList.length > 0) {
      for (let i = 0; i < deliveryUIDList.length; i++) {
        view = view + deliveryUIDList[i] + ' ';
      }
    }
    return view;
  };
  return (
    <GenericTemplate title="投稿一覧">
      {/* 検索フィールドとボタンを追加 */}
      <div>
        <input
          type="text"
          placeholder="UIDを入力"
          value={searchUID}
          onChange={(e) => setSearchUID(e.target.value)}
        />
        <Button onClick={getFirebaseItems}>検索</Button>
        <div
          style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}
        >
          {byPage ? (
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={prevLists.length <= 1}
                onClick={handlePrevPage}
              >
                前のPAGE
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextPage}
              >
                次のPAGE
              </Button>
            </div>
          ) : null}
          {isLoading ? (
            <div style={{ marginLeft: '10px', color: 'red' }}>
              データ更新中...
            </div>
          ) : null}
        </div>
      </div>
      <div>{searchUID}</div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>投稿ID</TableCell>
              <TableCell>投稿日時</TableCell>
              {/*   <TableCell>アバター</TableCell>
              <TableCell>ユーザ名</TableCell> */}
              <TableCell>uid</TableCell>
              <TableCell>メッセージ</TableCell>
              <TableCell>配信先</TableCell>
              <TableCell>status</TableCell>
              <TableCell>イメージ</TableCell>
            </TableRow>
          </TableHead>

          <>
            <TableBody>
              {posts.map((post, idx) => (
                // <PostList
                //   key={post.id}
                //   id={post.id}
                //   createdAt={post.createdAt}
                //   uid={post.uid}
                //   text={post.text}
                //   hashtag={post.hashtag}
                //   contents={post.contents}
                //   gLocation={post.gLocation}
                //   displayGLocation={post.displayGLocation}
                //   displayGLocationLabel={post.displayGLocationLabel}
                //   lastRefreshAt={post.lastRefreshAt}
                //   disclosureRange={post.disclosureRange}
                //   postStatus={post.postStatus}
                //   category={post.category}
                // />
                <TableRow
                  key={post.id}
                  hover
                  onClick={(event) => handleClick(post.id)}
                >
                  <TableCell>
                    {idx + 1 + idxOffset[idxOffset.length - 2]}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {post.id}
                  </TableCell>
                  <TableCell>
                    {new Date(post.createdAt?.toDate()).toLocaleString()}
                  </TableCell>
                  {/*    <TableCell>
        <Avatar src="" />
      </TableCell> */}
                  {/* <TableCell>{props.username}</TableCell> */}
                  <TableCell>
                    <div>
                      <Avatar src={post.avatarUrl} />
                      {post.userName}
                    </div>
                    <div>{post.uid}</div>
                  </TableCell>
                  <TableCell>{post.text}</TableCell>
                  <TableCell>
                    {/* {deliveryUIDListView(post.deliveryUIDList || [])} */}
                    {post.deliveryUIDList
                      ? `${post.deliveryUIDList.length} 件`
                      : '0 件'}
                  </TableCell>
                  <TableCell>{post.postStatus}</TableCell>
                  <TableCell>
                    {post.contents && (
                      <div className={styles.post_tweetImage_small}>
                        <img src={post.contents[0]} alt="tweet" />
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        </Table>
      </TableContainer>
      {/* <Button variant="contained" color="primary" onClick={handleNextPage}>
        次のLIST
      </Button> */}
    </GenericTemplate>
  );
};
export default PostCollection;
