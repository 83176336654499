import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import firebase from 'firebase/app';

// import { onLikedPost, offLikedPost } from "../../common/firebase/post";

// import { createPost } from "../../common/firebase/post";

// xross interfaces
import { XROSS_POST_EX, CONTENTS_ATTRIBUTE } from '../../common/interface';

import { postData as postDataSet } from '../TestDataStore/STPostDataSet';

// const POST_NUM = 10000;
// const POST_NUM = 10;

// 10000件のPOSTデータ登録
export const makeTestPostData = (
  uid: string,
  delivery: string,
  registPostNum: number,
  postText: string
) => {
  console.log('post データ取り込み');
  let postNum = 0;
  if (!registPostNum) {
    postNum = 100;
  } else {
    postNum = registPostNum;
  }
  const deliveryList: string[] = [uid, delivery];

  //
  const POST_ID_INDEX = 'maruko';

  (async () => {
    try {
      let no = 20000;
      for (let i = 0; i < postNum; i++) {
        // const timestamp = new Date();

        const postTextWithNumber = '(' + no + ') ' + postText;
        no = no + 1;

        const contents: string[] = [];
        const contentsAttributes: CONTENTS_ATTRIBUTE[] = [];
        for (let j = 0; j < postDataSet[i].conetnts.length; j++) {
          contents.push(postDataSet[i].conetnts[j]);
          contentsAttributes.push({
            coverContents: contents[j],
            mediaType: 'image',
            mediaUrl: contents[j],
          });
        }
        const postId =
          // 'kamome' + i + '_' + Math.random().toString(36).slice(-8);
          POST_ID_INDEX + no;
        const post: XROSS_POST_EX = {
          // id: 'POST' + postDataSet[i].no,
          id: postId,

          uid: uid, // 入力値を使用し、任意に変更できる
          // text: '(' + i + ') ' + postDataSet[i].text,
          // text: postText,
          text: postTextWithNumber,

          hashtag: [], // ハッシュタグ

          contents: contents, // 投稿画像・動画サムネイルのURL
          contentsAttributeList: contentsAttributes,

          gLocation: {
            latitude: 0,
            longitude: 0,
          },

          displayGLocation: {
            latitude: 0,
            longitude: 0,
          },
          displayGLocationLabel: postDataSet[i].displayGLocationLabel,
          disclosureRange: '_PUBLIC_',
          // deliveryUIDList: deliveryList,
          deliveryUIDList: [uid],
          postStatus: '_STORED_',
          category: '_NORMAL_',
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
          // userInfoUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
          userName: postDataSet[i].userName,
          avatarUrl: postDataSet[i].avatarUrl,
          // createdAt: timestamp,
          // lastRefreshAt: timestamp,

          // createdAt: {
          //   seconds: 0,
          //   nanoseconds: 0,
          // },
          // lastRefreshAt: {
          //   seconds: 0,
          //   nanoseconds: 0,
          // },
        };
        console.log('# ', i);
        console.log('post=', post);

        // firebase に登録
        await db.collection('posts').doc(String(postId)).set(post);
      }
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
  })();
};
