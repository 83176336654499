import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
// xross interfaces
import { XROSS_POST } from '../common/interface';
// view compornent
import PostShereView from '../components/PostShereView';
import PostShereViewError from '../components/PostShereViewError';
import { getPostDetail } from '../common/firebase/post';

type IdrProps = RouteComponentProps<{
  id: string;
}>;

/*
 * SNS にシェアされた場合に表示するページ
 * postId を引数にして、投稿内容を表示する
 * 表示する内容は、
 * 　投稿者アバター、投稿者の名称、投稿者のxrossID
 * 　投稿画像
 * 　投稿コメント
 * 　いいねカウンター、返信コメント数、シェアボタン
 * 　投稿者の位置情報を地図で表示
 */

const ShereFeed: React.FC<IdrProps> = (props) => {
  console.log('>>ShereFeed');

  // 引き渡されたpostId
  const postId = props.match.params.id;
  console.log('postId:' + postId);

  // postIdチェック
  const [propsError, setPropsError] = useState(true);

  const [postDataSet, setPostDataSet] = useState<XROSS_POST>({
    id: postId, // ドキュメントID(postId)
    uid: '', //firebaseのuid
    text: ' ',
    hashtag: [],
    contents: [],
    //投稿時の位置情報（表示に使用しない）
    gLocation: {
      latitude: 0,
      longitude: 0,
    },
    //表示用位置情報（ユーザ設定が、ホームの場合はホームの位置情報、GPSの場合はGPSの位置情報）
    displayGLocation: {
      latitude: 0,
      longitude: 0,
    },
    displayGLocationLabel: '',
    disclosureRange: '_PUBLIC_', //公開範囲
    postStatus: '_STORED_',
    category: '_NORMAL_',
  });

  useEffect(() => {
    getFirebaseItems();
  }, [propsError]);

  const getFirebaseItems = async () => {
    if (postId != null) {
      const postDoc = await getPostDetail(postId);
      if (postDoc) {
        setPostDataSet(postDoc);
        setPropsError(false); // postId が正常
      }
    }
  };

  return propsError ? (
    <PostShereViewError />
  ) : (
    <PostShereView {...postDataSet} />
  );
};

export default ShereFeed;
