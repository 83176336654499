import { db } from '../../firebase';
import firebase from 'firebase/app';
import * as admin from 'firebase-admin';

// xross interfaces
import { XROSS_FRIEND } from '../../common/interface';
import { getFriendship } from '../../common/task/Friendship';
import { makeFriendsArrayWithMe } from './makeFriendsArray';

// posts の配信先UIDを更新する
export const refreshPostsByUID = async (uid: string) => {
  try {
    const friendship = await getFriendship(uid);
    if (friendship === undefined) return;
    const myFriendList: XROSS_FRIEND[] = friendship.myFriendList;
    const friendArray: string[] = makeFriendsArrayWithMe(uid, myFriendList);
    console.log('>>>>> friends ', friendArray);

    // 指定したUIDの全post の配信先を更新する

    // const db = admin.firestore();

    const snapshot = await db
      .collectionGroup('posts')
      .where('uid', '==', uid)
      .get();

    const batch = db.batch();
    snapshot.docs.forEach((postDoc) => {
      console.log('+++++ postId ', postDoc.id);
      const updateDeliveryList = {
        deliveryUIDList: friendArray,
      };
      batch.update(postDoc.ref, updateDeliveryList);
    });
    await batch.commit();
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

// 指定したUIDをdeliveryUIDList に追加する
export const addDeliveryList = async (actionUid: string, targetUid: string) => {
  console.log(
    'addDeliveryList actionUid=',
    actionUid,
    ' targetUid=',
    targetUid
  );

  // action ユーザの投稿
  const actionPosts = await db
    .collectionGroup('posts')
    .where('uid', '==', actionUid)
    .get();

  // target ユーザの投稿
  const targetPosts = await db
    .collectionGroup('posts')
    .where('uid', '==', targetUid)
    .get();

  // 該当する投稿の deliveryUIDList を追加する
  const addDeliveryUid = async (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    uid: string
  ) => {
    console.log(
      '@@@@@ addDeliveryUid Start.....',
      snapshot.docs.length,
      ' 件 '
    );

    const batch = db.batch();
    snapshot.docs.forEach((postDoc) => {
      const deliveryUIDList = postDoc.data().deliveryUIDList;
      // uid がなければ追加する
      let no = 0;
      if (!deliveryUIDList.some((item: string) => item === uid)) {
        const newDeliveryUIDList = [...deliveryUIDList, uid];

        console.log(no, ' before:', deliveryUIDList);
        console.log(no, ' after:', newDeliveryUIDList);
        batch.update(postDoc.ref, { deliveryUIDList: newDeliveryUIDList });
        no = no + 1;
      }
    });
    await batch.commit();
    console.log('@@@@@ addDeliveryUid End.....');
  };

  // ページネーションのバッチ処理
  const addProcessPostsInBatches = async (
    posts: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    uid: string
  ) => {
    const batchSize = 500;
    let start = 0;
    let end = batchSize;

    console.log(
      '@@@@@ addProcessPostsInBatches Start.....',
      posts.docs.length,
      ' 件 '
    );

    while (start < posts.docs.length) {
      console.log('@@@@@ addProcessPostsInBatches ', start, ' > ');

      const batch = db.batch();
      const batchDocs = posts.docs.slice(start, end);

      batchDocs.forEach((postDoc) => {
        const deliveryUIDList = postDoc.data().deliveryUIDList;
        // uid がなければ追加する
        if (
          deliveryUIDList &&
          !deliveryUIDList.some((item: string) => item === uid)
        ) {
          const newDeliveryUIDList = [...deliveryUIDList, uid];
          batch.update(postDoc.ref, { deliveryUIDList: newDeliveryUIDList });
        }
      });
      await batch.commit();

      start = end;
      end += batchSize;
    }
  };

  // 友達になった時
  const makeFriendship = () => {
    console.log('+++++ makeFriendship');
    // actionUid の全投稿のdeliveryUIDList から targetUid を追加する
    // addDeliveryUid(actionPosts, targetUid);
    addProcessPostsInBatches(actionPosts, targetUid);
    // targetUid の全投稿のdeliveryUIDList から actionUid を追加する
    // addDeliveryUid(targetPosts, actionUid);
    addProcessPostsInBatches(targetPosts, actionUid);
  };

  makeFriendship();
};

// 指定したUIDをdeliveryUIDList から削除する
export const delDeliveryList = async (actionUid: string, targetUid: string) => {
  console.log(
    'delDeliveryList actionUid=',
    actionUid,
    ' targetUid=',
    targetUid
  );

  // action ユーザの投稿
  const actionPosts = await db
    .collectionGroup('posts')
    .where('uid', '==', actionUid)
    .get();

  // target ユーザの投稿
  const targetPosts = await db
    .collectionGroup('posts')
    .where('uid', '==', targetUid)
    .get();

  // 該当する投稿の deliveryUIDList から削除する
  const delDeliveryUid = async (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    uid: string
  ) => {
    try {
      const batch = db.batch();
      snapshot.docs.forEach((postDoc) => {
        const deliveryUIDList = postDoc.data().deliveryUIDList;

        const newDeliveryUIDList = deliveryUIDList.filter(
          (n: string) => n !== uid
        );
        batch.update(postDoc.ref, { deliveryUIDList: newDeliveryUIDList });
      });
      await batch.commit();
    } catch (e) {
      console.log('----- e=', e);
    }
  };

  // ページネーションのバッチ処理
  const dellProcessPostsInBatches = async (
    posts: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    uid: string
  ) => {
    const batchSize = 500;
    let start = 0;
    let end = batchSize;

    console.log(
      '@@@@@ dellProcessPostsInBatches Start.....',
      posts.docs.length,
      ' 件 '
    );

    while (start < posts.docs.length) {
      console.log('@@@@@ dellProcessPostsInBatches ', start, ' > ');

      const batch = db.batch();
      const batchDocs = posts.docs.slice(start, end);

      batchDocs.forEach((postDoc) => {
        const deliveryUIDList = postDoc.data().deliveryUIDList;
        const newDeliveryUIDList = (deliveryUIDList || []).filter(
          (n: string) => n !== uid
        );
        batch.update(postDoc.ref, { deliveryUIDList: newDeliveryUIDList });
      });
      await batch.commit();

      start = end;
      end += batchSize;
    }
  };

  // 友達解除した時
  const brokeFriendship = () => {
    console.log('+++++ brokeFriendship');
    // actionUid の全投稿のdeliveryUIDList から targetUid を削除する
    // delDeliveryUid(actionPosts, targetUid);
    dellProcessPostsInBatches(actionPosts, targetUid);
    // targetUid の全投稿のdeliveryUIDList から actionUid を削除する
    // delDeliveryUid(targetPosts, actionUid);
    dellProcessPostsInBatches(targetPosts, actionUid);
  };

  brokeFriendship();
};

// 指定したUIDが投稿したデータのアバターと名前を変更する
enum CHANGR_MODE {
  NON,
  STATUS_ACTIVE,
  STATUS_BAN,
  STATUS_UNSUBSCRIBE,
  INFO,
}
export const onUpdateUser = async (
  uid: string,
  avatar: string,
  userName: string
) => {
  console.log('onUpdateUser...');
  console.log('avatar=', avatar);
  console.log('userName=', userName);
  await onUpdateUserExecute(uid, avatar, userName);
  // await onUpdateUserExecuteTest(
  //   'gDkFU2aRUpTKVRCF8ayDCVEifjz2',
  //   'https://i0.wp.com/sozaikoujou.com/wordpress/wp-content/uploads/2015/07/eto_034.png?w=600&ssl=1',
  //   userName
  // );
};

const onUpdateUserExecute = async (
  uid: string,
  avatar: string,
  userName: string
) => {
  let changeMode = CHANGR_MODE.INFO;

  let hasError = false;

  const batchSize = 100;
  const maxProcessPerBatch = 10000;
  // const db = admin.firestore();

  let query = db
    .collectionGroup('posts')
    .where('uid', '==', uid)
    .orderBy('createdAt', 'desc')
    .limit(maxProcessPerBatch);

  let procesNum = 0;
  let lastDoc = null;

  try {
    let hasMoreDocuments = true;

    while (hasMoreDocuments) {
      console.log('===== next query... =====');
      if (lastDoc) {
        query = query.startAfter(lastDoc);
      }

      const snapshot = await query.get();

      if (snapshot.empty) {
        console.log('No documents to process');
        hasMoreDocuments = false;
        break;
      }

      console.log('Start / pubsub Total documents in snapshot:', snapshot.size);
      let batch = db.batch();
      let batchCount = 0;

      for (const doc of snapshot.docs) {
        // const docId = doc.id;
        try {
          switch (changeMode) {
            // case CHANGR_MODE.STATUS_ACTIVE:
            //   const activeStatus = doc.data().postStatus;
            //   const activePrefix = activeStatus.substr(0, 3);
            //   if (activePrefix === "BAN" || activePrefix === "UNS") {
            //     let restoreStatus = activeStatus.substr(3);
            //     await doc.ref.update({ postStatus: restoreStatus });
            //   }
            //   break;

            // case CHANGR_MODE.STATUS_BAN:
            //   const banStatus = doc.data().postStatus;
            //   const banPrefix = banStatus.substr(0, 3);
            //   if (banPrefix !== "BAN" && banPrefix !== "UNS") {
            //     await doc.ref.update({ postStatus: "BAN" + banStatus });
            //   }
            //   break;

            // case CHANGR_MODE.STATUS_UNSUBSCRIBE:
            //   const unsubStatus = doc.data().postStatus;
            //   const unsubPrefix = unsubStatus.substr(0, 3);
            //   if (unsubPrefix !== "UNS") {
            //     await doc.ref.update({ postStatus: "UNS" + unsubStatus });
            //   }
            //   break;

            case CHANGR_MODE.INFO:
              await doc.ref.update({
                avatarUrl: avatar,
                userName: userName,
                userInfoUpdateAt:
                  firebase.firestore.FieldValue.serverTimestamp(),
              });
              console.log(procesNum + `) Document update set : ${doc.id}`);

              // await db.collection('posts').doc(docId).update({
              //   avatarUrl: avatar,
              //   userName: userName,
              //   // userInfoUpdateAt:
              //   //   firebase.firestore.FieldValue.serverTimestamp(),
              // });
              // console.log(
              //   `Updated document ${doc.id} avatar=${avatar} userName=${userName}`
              // );
              break;
          }

          batchCount++;
          procesNum++;

          lastDoc = doc;

          if (batchCount >= batchSize) {
            console.log(
              `Committing batch... (processed: ${procesNum}) snapshotSize=${snapshot.size}`
            );
            try {
              await batch.commit();
              console.log('commited...', doc.id);
            } catch (err) {
              console.log(
                'commit error =',
                err,
                ' batchCount=',
                batchCount,
                ' doc=',
                doc
              );
            } finally {
              batch = db.batch();
              batchCount = 0;
              // await sleep(100); // 短い遅延を追加
            }
          }
        } catch (err) {
          console.error(`Error updating document ${doc.id}:`, err);
          // hasError = true;
        }
      }

      if (procesNum >= maxProcessPerBatch && lastDoc) {
        const lastDocSnapshot = await db.doc(lastDoc.ref.path).get();
        query = query.startAfter(lastDocSnapshot);
      } else {
        hasMoreDocuments = false;
      }
    }

    console.log('All documents processed');
  } catch (err) {
    console.log('processUserUpdate error =', err);

    // hasError = true;
  }

  return null;
};

const onUpdateUserExecuteTest = async (
  uid: string,
  avatar: string,
  userName: string
) => {
  const problematicDocId = 'maruko2798'; // エラーの原因となっているドキュメントID
  const docRef = db.collection('posts').doc(problematicDocId);
  let procesNum = 0;

  try {
    const docSnapshot = await docRef.get();
    const docData = docSnapshot.data();
    const docSize = Buffer.byteLength(JSON.stringify(docData));
    console.log('document size = ', docSize);

    if (!docSnapshot.exists) {
      console.log('Document does not exist:', problematicDocId);
      return;
    } else {
      console.log('Document does exist:', problematicDocId);
    }

    let batch = db.batch();

    batch.update(docRef, {
      avatarUrl: avatar,
      userName: userName,
      userInfoUpdateAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    console.log(`Committing batch... (processed: ${procesNum})`);
    try {
      await batch.commit();
      console.log('Committed...');
    } catch (err) {
      console.log('Commit error:', err);
    } finally {
      // await sleep(100); // 短い遅延を追加
    }
  } catch (err) {
    console.log('Error updating document:', err);
  }
};
