import React, { useState } from 'react';
//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';

// View
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Modal,
  IconButton,
  Box,
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';

import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

// xross interfaces
import { XROSS_USER_PROFILE } from '../common/interface';

// xross common function
import { updateXrossUserForce } from '../common/firebase/user';

// xross common function
//import { UpdateXrossUserForce } from "../common/UpdateXrossUserForce";

// xross web page styles
import { xrossUseStyles } from './pageStyles';

const UserDetailEdit: React.FC<XROSS_USER_PROFILE> = (xrossUserProfile) => {
  const classes = xrossUseStyles();
  console.log('xrossUserID=' + xrossUserProfile.xrossUserID);

  // 更新用拡張ユーザ情報を取得する
  const [updateXrossUserProfile, setUpdateXrossUserProfile] =
    useState<XROSS_USER_PROFILE>({
      id: xrossUserProfile.id, // ドキュメントID(uid)
      xrossUserID: xrossUserProfile.xrossUserID,
      xrossUserName: xrossUserProfile.xrossUserName,
      xrossAvatarURL: xrossUserProfile.xrossAvatarURL,
      xrossUserStatus: xrossUserProfile.xrossUserStatus,
      profile: xrossUserProfile.profile,
      homeGLocation: {
        latitude: xrossUserProfile.homeGLocation
          ? xrossUserProfile.homeGLocation.latitude
          : 0,
        longitude: xrossUserProfile.homeGLocation
          ? xrossUserProfile.homeGLocation.longitude
          : 0,
      },
      homeGLocationLabel: xrossUserProfile.homeGLocationLabel,
      isDefaultLocationAtHome: xrossUserProfile.isDefaultLocationAtHome,
      defaultPostDisclosureRange: xrossUserProfile.defaultPostDisclosureRange,
      createdAt: xrossUserProfile.createdAt,
      lastRefreshAt: xrossUserProfile.lastRefreshAt,
    });

  const submitPost = () => {
    console.log('commited!');
    //    UpdateXrossUserForce(updateXrossUserProfile);
    updateXrossUserForce(updateXrossUserProfile);
  };

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar src={updateXrossUserProfile.xrossAvatarURL} />

            <form className={classes.form} noValidate>
              <Grid>
                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="uid"
                  type="text"
                  label="uid"
                  autoComplete="uid"
                  value={updateXrossUserProfile.id}
                  autoFocus
                  disabled
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="xrossUserID"
                  type="text"
                  label="xrossUserID"
                  autoComplete="xrossUserID"
                  value={updateXrossUserProfile.xrossUserID}
                  autoFocus
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateXrossUserProfile({
                      ...updateXrossUserProfile,
                      xrossUserID: e.target.value,
                    });
                  }}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="xrossUserName"
                  type="text"
                  label="xrossUserName"
                  autoComplete="xrossUserName"
                  value={updateXrossUserProfile.xrossUserName}
                  autoFocus
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateXrossUserProfile({
                      ...updateXrossUserProfile,
                      xrossUserName: e.target.value,
                    });
                  }}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="xrossAvatarURL"
                  type="text"
                  label="xrossAvatarURL"
                  autoComplete="xrossAvatarURL"
                  value={updateXrossUserProfile.xrossAvatarURL}
                  autoFocus
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateXrossUserProfile({
                      ...updateXrossUserProfile,
                      xrossAvatarURL: e.target.value,
                    });
                  }}
                />

                <FormControl>
                  <InputLabel id="xrossUserStatus">ユーザ状態</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="xrossUserStatus"
                    id="xrossUserStatus"
                    displayEmpty
                    value={updateXrossUserProfile.xrossUserStatus}
                    onChange={(e) => {
                      setUpdateXrossUserProfile({
                        ...updateXrossUserProfile,
                        xrossUserStatus: e.target.value as string,
                      });
                    }}
                  >
                    <MenuItem value={'_ACTIVE_'}>_ACTIVE_</MenuItem>
                    <MenuItem value={'_SUSPEND_'}>_SUSPEND_</MenuItem>
                    <MenuItem value={'_UNSUBSCRIBE_'}>_UNSUBSCRIBE_</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="profile"
                  type="text"
                  label="profile"
                  autoComplete="profile"
                  multiline
                  rows={4}
                  value={updateXrossUserProfile.profile}
                  autoFocus
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateXrossUserProfile({
                      ...updateXrossUserProfile,
                      profile: e.target.value,
                    });
                  }}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={updateXrossUserProfile.isDefaultLocationAtHome}
                      onChange={(e) => {
                        setUpdateXrossUserProfile({
                          ...updateXrossUserProfile,
                          isDefaultLocationAtHome: e.target.checked,
                        });
                      }}
                    />
                  }
                  label="投稿時にホーム位置を使用する"
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="homeGLocation.latitude"
                  type="text"
                  label="ホーム位置情報（緯度）"
                  autoComplete="homeGLocation.latitude"
                  value={
                    xrossUserProfile.homeGLocation
                      ? xrossUserProfile.homeGLocation.latitude
                      : 'N/A'
                  }
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="homeGLocation.longitude"
                  type="text"
                  label="ホーム位置情報（経度）"
                  autoComplete="homeGLocation.longitude"
                  value={
                    xrossUserProfile.homeGLocation
                      ? xrossUserProfile.homeGLocation.longitude
                      : 'N/A'
                  }
                  autoFocus
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="homeGLocationLabel"
                  type="text"
                  label="homeGLocationLabel"
                  autoComplete="homeGLocationLabel"
                  value={updateXrossUserProfile.homeGLocationLabel}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUpdateXrossUserProfile({
                      ...updateXrossUserProfile,
                      homeGLocationLabel: e.target.value,
                    });
                  }}
                />

                <FormControl>
                  <InputLabel id="defaultPostDisclosureRange">
                    投稿の公開範囲
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="defaultPostDisclosureRange"
                    id="defaultPostDisclosureRange"
                    displayEmpty
                    value={updateXrossUserProfile.defaultPostDisclosureRange}
                    onChange={(e) => {
                      setUpdateXrossUserProfile({
                        ...updateXrossUserProfile,
                        defaultPostDisclosureRange: e.target.value as string,
                      });
                    }}
                  >
                    <MenuItem value={'_PUBLIC_'}>_PUBLIC_</MenuItem>
                    <MenuItem value={'_BETWEEN_FRIENDS_'}>
                      _BETWEEN_FRIENDS_
                    </MenuItem>
                    <MenuItem value={'_PERSONAL_'}>_PERSONAL_</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="createdAt"
                  type="text"
                  label="作成日時"
                  autoComplete="createdAt"
                  value={
                    updateXrossUserProfile.createdAt === undefined ||
                    updateXrossUserProfile.createdAt === null
                      ? 'LOADING'
                      : // : new Date(
                        //     xrossUserProfile.createdAt?.toDate()
                        //   ).toLocaleString()
                        'Not Edit'
                  }
                  autoFocus
                  disabled
                />

                <TextField
                  variant="standard"
                  margin="normal"
                  required
                  fullWidth
                  id="lastRefreshAt"
                  type="text"
                  label="更新日時"
                  autoComplete="lastRefreshAt"
                  value={
                    updateXrossUserProfile.lastRefreshAt === undefined ||
                    updateXrossUserProfile.lastRefreshAt === null
                      ? 'LOADING'
                      : // : new Date(
                        //     xrossUserProfile.lastRefreshAt?.toDate()
                        //   ).toLocaleString()
                        'Not Edit'
                  }
                  autoFocus
                  disabled
                />
              </Grid>

              <Button
                //type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                //    disabled={!formState.isDirty || formState.isSubmitting}
                onClick={submitPost}
              >
                登録する！
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserDetailEdit;
