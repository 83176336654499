/*
 * 友達の配列を作成する
 */
import { XROSS_FRIEND } from '$common/interface';

export const makeFriendsArray = (myFriendList: XROSS_FRIEND[]) => {
  const friends = [];
  for (let i = 0; i < myFriendList.length; i++) {
    friends.push(myFriendList[i].uid);
  }
  return friends;
};

export const makeFriendsArrayWithMe = (
  myUID: string,
  myFriendList: XROSS_FRIEND[]
) => {
  const friends = [];
  friends.push(myUID);
  for (let i = 0; i < myFriendList.length; i++) {
    friends.push(myFriendList[i].uid);
  }
  return friends;
};
