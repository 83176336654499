import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import firebase from 'firebase/app';

import { onLikedPost, offLikedPost } from '../../common/firebase/post';

import { createPost } from '../../common/firebase/post';

// xross interfaces
import { XROSS_USER_PROFILE, XROSS_POST } from '../../common/interface';

// sample data
import { locationSample } from '../TestDataStore/postDataSet';
import { uri } from '../TestDataStore/UriDataSet';

export const makeTestPostData = async () => {
  const samplePostDS: XROSS_POST[] = [];

  for (let i = 0; i < 50; i++) {
    samplePostDS[i] = {
      id: String(i),
      uid: String(i),
      text: 'こんにちは' + String(i) + 'です。',
      hashtag: [''],
      contents: [uri[i], uri[i + 1], uri[i + 2]],
      gLocation: {
        latitude: Number(locationSample[i][2]),
        longitude: Number(locationSample[i][3]),
      },
      displayGLocation: {
        latitude: 0,
        longitude: 0,
      },
      displayGLocationLabel: locationSample[i][1],
      // createdAt: 0,
      // lastRefreshAt: 0,
      disclosureRange: '',
      postStatus: '',
      category: '_NORMAL_',
    };
  }

  samplePostDS.map(async (doc) => await createPost(doc));
};

export const makeTestUserData = () => {
  (async () => {
    try {
      let url = '';
      for (let id = 0; id < 100; id++) {
        let name = 'user' + id;
        //  await db.collection("xrossUser").add({
        await db
          .collection('xrossUser')
          .doc(String(id))
          .set({
            xrossUserID: String(id),
            xrossUserName: name,
            xrossAvatarURL: url,
            xrossUserStatus: '_ACTIVE_',
            profile: 'こんにちは' + name + 'です。',
            //homeGLocation: ,
            homeGLocationLabel: 'にほん',
            isDefaultLocationAtHome: false,
            defaultPostDisclosureRange: '_PUBLIC_',
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
  })();
};

export const makeOnLikedPost = async (postId: string) => {
  for (let id = 0; id < 100; id++) {
    await onLikedPost(String(id), postId);
  }
};

export const makeOffLikedPost = async (postId: string) => {
  for (let id = 0; id < 100; id++) {
    await offLikedPost(String(id), postId);
  }
};
