import React, { useState, useEffect } from 'react';
//import { Avatar } from "@material-ui/core";
import Avatar from '@mui/material/Avatar';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

// xross interfaces
import { XROSS_POST, XROSS_PUBLIC_USER_INFO } from '../common/interface';
import { getPublicUserInfo } from '../common/firebase/user';

import ShowMarker from '../components/ShowMarker';
import ShowMCarousel from '../components/ShowCarousel';
import mapStyles from './MAP/mapStyles/Shades_of_Grey';
// APIキー
const API_KEY = 'AIzaSyAe3ZPuxHh6gF5knxwMhE0ari72C7XJ8bU';

const initialUser: XROSS_PUBLIC_USER_INFO = {
  id: '00000',
  xrossUserID: '',
  xrossUserName: '',
  xrossAvatarURL: '',
  xrossUserStatus: '',
  profile: '',
  homeGLocation: {
    latitude: '',
    longitude: '',
  },
  homeGLocationLabel: '',
  // createdAt: null,
  // lastRefreshAt: null,
};

const options = {
  // style は、https://snazzymaps.com/　で作成
  styles: mapStyles,
  // デフォルトUI（衛星写真オプションなど）をキャンセルします。
  disableDefaultUI: true,
  zoomControl: true,
};

/*
 * PostShereView
 */

const PostShereView: React.FC<XROSS_POST> = (postDataSet) => {
  console.log('PostShereView PostId=' + postDataSet.id);

  // データクレンジング
  if (
    postDataSet.displayGLocation == null ||
    postDataSet.displayGLocation.latitude === 0 ||
    postDataSet.displayGLocation.longitude === 0
  ) {
    postDataSet.displayGLocation.latitude = Number(
      postDataSet.gLocation.latitude
    );
    postDataSet.displayGLocation.longitude = Number(
      postDataSet.gLocation.longitude
    );
  }

  const center = {
    lat: Number(postDataSet.displayGLocation.latitude),
    lng: Number(postDataSet.displayGLocation.longitude),
  };

  let GPScenter = {
    lat: Number(postDataSet.gLocation.latitude),
    lng: Number(postDataSet.gLocation.longitude),
  };

  const containerStyle = {
    height: '50vh',
    width: '100%',
  };

  // 引数から必要な情報を取得
  const uid = postDataSet.uid;

  // 投稿ユーザの情報を取得
  const [userInfo, setUserInfo] = useState<XROSS_PUBLIC_USER_INFO>(initialUser);

  useEffect(() => {
    getUserInfo();
    /*     const unSub = async () => {
      const user = await getPublicUserInfo(uid);
      console.log("user ", user);
      if (user != null) {
        setUserInfo(user);
      }
    };
    return () => {
      unSub(); // cleanup
    }; */
  }, [uid]);

  const getUserInfo = async () => {
    const user = await getPublicUserInfo(uid);
    console.log('user ', user);
    if (user != null) {
      setUserInfo(user);
    }
  };

  // 投稿データが「一般公開」かどうかの判断

  return (
    <>
      <h3>xross.club</h3>
      <div>
        <Avatar src={userInfo?.xrossAvatarURL} />
        <div>ユーザID：{userInfo?.xrossUserID}</div>
        <div>ユーザ名：{userInfo?.xrossUserName}</div>
      </div>
      {postDataSet.contents[0] && (
        <ShowMCarousel imageUrl={postDataSet.contents} />
      )}
      <div>投稿内容：{postDataSet.text}</div>
      <LoadScript googleMapsApiKey={API_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
          options={options}
        >
          <>
            <ShowMarker
              userName={userInfo.xrossUserName}
              avatar={userInfo.xrossAvatarURL}
              latitude={String(postDataSet.gLocation.latitude)}
              longitude={String(postDataSet.gLocation.longitude)}
              locationLabel={postDataSet.displayGLocationLabel}
            />
          </>
        </GoogleMap>
      </LoadScript>
    </>
  );
};

export default PostShereView;
