import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import firebase from "firebase/app";

import { onLikedPost, offLikedPost } from "../../common/firebase/post";

import { createPost } from "../../common/firebase/post";

// xross interfaces
import { XROSS_USER_PROFILE, XROSS_POST } from "../../common/interface";

// sample data
import { locationSample } from "../TestDataStore/postDataSet";
import { uri } from "../TestDataStore/UriDataSet";
import { avatar } from "../TestDataStore/UriDataSet";

// 100件のユーザ登録
export const makeTestUserData = () => {
  (async () => {
    try {
      for (let id = 0; id < 100; id++) {
        let name = "user" + id;
        //  await db.collection("xrossUser").add({
        await db
          .collection("xrossUser")
          .doc(String(id))
          .set({
            xrossUserID: String(id),
            xrossUserName: name,
            xrossAvatarURL: avatar[id],
            xrossUserStatus: "_ACTIVE_",
            profile: "こんにちは" + name + "です。",
            //homeGLocation: ,
            homeGLocationLabel: "にほん",
            isDefaultLocationAtHome: false,
            defaultPostDisclosureRange: "_PUBLIC_",
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            lastRefreshAt: firebase.firestore.FieldValue.serverTimestamp(),
          });
      }
    } catch (err) {
      console.log(`Error: ${JSON.stringify(err)}`);
    }
  })();
};
