import { db } from '../../firebase';

// 投稿データのstatusをBANにする
export const onChangePostsStatusBanByUID = async (uid: string) => {
  try {
    // 指定したUIDの全post のstatusをBAN状態に更新する
    // const db = admin.firestore();
    const snapshot = await db
      .collectionGroup('posts')
      .where('uid', '==', uid)
      .get();

    const batch = db.batch();
    snapshot.docs.forEach((postDoc) => {
      const status = postDoc.data().postStatus;
      console.log('+++++ postId ', postDoc.id, ' ', status);

      const prefix = status.substr(0, 3);
      // 既にBANだったら処理を中止
      if (prefix !== 'BAN' && prefix !== 'UNS') {
        const newPostStatus = {
          postStatus: 'BAN' + status,
        };
        batch.update(postDoc.ref, newPostStatus);
      }
    });
    await batch.commit();
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};

// 投稿データのstatusをBANから復活する
export const onChangePostsStatusRestoreByUID = async (uid: string) => {
  try {
    // 指定したUIDの全post のstatusをBAN状態から復活させる
    // const db = admin.firestore();
    const snapshot = await db
      .collectionGroup('posts')
      .where('uid', '==', uid)
      .get();

    const batch = db.batch();
    snapshot.docs.forEach((postDoc) => {
      const status = postDoc.data().postStatus;
      console.log('----- postId ', postDoc.id, ' ', status);

      const prefix = status.substr(0, 3);
      console.log('----- postId ', postDoc.id, ' prefix ', prefix);
      // BANからの復活のみ
      if (prefix === 'BAN') {
        let restoreStatus = status.substr(3);
        console.log(
          '----- postId ',
          postDoc.id,
          ' ',
          status,
          ' >',
          restoreStatus
        );
        const newPostStatus = {
          postStatus: restoreStatus,
        };
        batch.update(postDoc.ref, newPostStatus);
      }
    });
    await batch.commit();
  } catch (err) {
    console.log(`Error: ${JSON.stringify(err)}`);
  }
};
