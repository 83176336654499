import React, { useEffect } from 'react';
//import styles from "./App.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, login, logout } from './features/userSlice';
import { auth } from './firebase';

import Auth from './components/Auth';
import Management from './Management';

// xross utils
import { setXrossUSerProfile } from './adminUtil/adminXrossUtil';
import { getXrossUserProfile } from './common/firebase/user';

//
// xross.club Moon 運営管理サーバ
// 2021-11-13
const App: React.FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    // userの状態を監視する
    const unSub = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // util : xrossUSerProfile Setting
        // ログイン成功時にxrossUserProfile を更新する
        // const currentUser = auth.currentUser;
        // if (currentUser) {
        //   setXrossUSerProfile(currentUser);
        //   //updateUser(authUser.uid, initialUser);
        // }

        // XROSS_USER 情報を取得する
        (async () => {
          const xrossUser = await getXrossUserProfile(authUser.uid);
          dispatch(
            login({
              uid: authUser.uid,
              photoUrl: xrossUser?.xrossAvatarURL,
              displayName: xrossUser?.xrossUserName,
              email: xrossUser?.email,
            })
          );
        })();
      } else {
        dispatch(logout());
      }
    });
    // cleanup処理
    return () => {
      unSub();
    };
  }, [dispatch]); //useEffectの第２引数に dispatch を指定
  return (
    <>
      {user.uid ? (
        // userが存在するとき 管理画面コンポーネントが呼び出される
        <div>
          <Management />
        </div>
      ) : (
        // userが存在しないとき Authコンポーネントが呼び出される
        <Auth />
      )}
    </>
  );
};

export default App;
