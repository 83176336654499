/*
 * テキストから @xxxx #xxxx url を抽出する
 */
export type TEXT_EXTRACT = {
  tags: string[] | null;
  accounts: string[] | null;
  urls: string[] | null;
};
export const textAnalyzer = (text: string) => {
  // ハッシュタグ抽出
  const tags = text.match(
    /[#＃][Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー._-]+/gm
  );

  // アカウント抽出
  const accounts = text.match(/[@＠][A-Za-z0-9._-]+/gm);

  // URL抽出
  const urls = text.match(
    /([a-z0-9]|\.)+\.(com|net|jp|org|io|info|me|ma|gl|ly|gd|cc|nu|tt).*/gm
  );

  // 解析結果
  const result: TEXT_EXTRACT = {
    tags,
    accounts,
    urls,
  };
  return result;
};
